import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Tree from './Tree';
import Explorer from './Explorer';
import UploadModal from './UploadModal';
import { getBusiness } from 'helpers/business';
import { default as FilesService } from 'services/Files';

const DocumentsContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

const DropCristal = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 999999;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: white;
	font-size: 30px;
`;

const TreeContainer = styled.div`
	min-width: 250px;
	max-width: 250px;

	@media (max-width: 768px) {
		position: absolute;
		height: 100%;
		left: ${props => props.opened ? '0' : '-100'}%;
		z-index: 998;
		transition: all 0.2s;
		width: 100%;
		min-width: 100%;
		max-width: 100%;

		& > div {
			width: 70%;
			border-right: 1px solid var(--bs-gray-300);
		}
	}
`;

const ButtonToggleTree = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 999;
`;

export default function Documents(props) {
	const [folder, _setFolder] = useState([]);
	const folderRef = useRef(folder?.id);
	const setFolder = (folder) => {
		folderRef.current = {...folder};
		_setFolder(folder);
	}

	const [reloadTree, setReloadTree] = useState(null);
	const [droppingFiles, setDroppingFiles] = useState(false);
	const [uploadModalOpened, setUploadModalOpened] = useState(false);
	const [treeOpened, setTreeOpened] = useState(false); // Only for responsive

	const [business, _setBusiness] = useState(props.business ?? getBusiness());
	const businessRef = useRef(business?.id);
	const setBusiness = (business) => {
		businessRef.current = {...business};
		_setBusiness(business);
	}

	const isPanel = props.panel === true ? true : false;
	const isFilePicker = props.isFilePicker;
	
	const filesToUploadRef = useRef([]);

	const openFolder = useCallback(async (el) => {
		let result = await FilesService.getFolder(el?.id ?? -1, business?.id);
		if ( result ) {
			setFolder({...result});
		}
	}, [business?.id, props.reloadFolder]);

	useEffect(() => {
		setBusiness(props.business ?? getBusiness());
	}, [props.business]);

	// When business changes, it fires useCallback of openFolder and openFolder fires this useEffect
	useEffect(() => {
		let folderToOpenId = null;
		if ( folderRef.current?.id ) {
			folderToOpenId = folderRef.current?.id;

			// If trying to load folder from other business, load root foolder
			if ( folderRef.current.business_id && businessRef.current.id && 
					folderRef.current.business_id !== businessRef.current ) {
				folderToOpenId = null;
			}
		}
		if ( props.rootFolderId ) folderToOpenId = props.rootFolderId;			

		openFolder({id: folderToOpenId});
	}, [openFolder, props.rootFolderId]);

	// If business selected and is root folder, select business folder
	useEffect(() => {
		if ( business?.id && folder?.id === -1 ) {
			let businessFolder = folder.subfolders.find(el => el.id === business.folder_id);
			openFolder(businessFolder);
		}
	}, [folder, business, openFolder]);

	const dropHandler = (e) => {
		e.preventDefault();

		// Check if modal opened
		if ( uploadModalOpened ) {
			toast.error('Debes cerrar la subida de documentos para poder arrastrar nuevos documentos aquí');
			return false;
		}

		// Check if root folder
		if ( folder?.id === -1 ) {
			toast.error('Solo se pueden arrastrar archivos dentro de una carpeta');
			return false;
		}

		setDroppingFiles(false);

		let filesToUpload = [];

		if (e.dataTransfer.items) {
		    // Usar la interfaz DataTransferItemList para acceder a el/los archivos)
		    for (let i = 0; i < e.dataTransfer.items.length; i++) {
		      	// Si los elementos arrastrados no son ficheros, rechazarlos
		      	if (e.dataTransfer.items[i].kind === 'file') {
		        	let file = e.dataTransfer.items[i].getAsFile();
		        	filesToUpload.push(file);
		      	}
		    }
	  	} else {
		    // Usar la interfaz DataTransfer para acceder a el/los archivos
		    for (let i = 0; i < e.dataTransfer.files.length; i++) {
		    	let file = e.dataTransfer.files[i];
		    	filesToUpload.push(file);
		    }
	  	}

	  	// Pasar el evento a removeDragData para limpiar
	  	removeDragData(e)

	  	// Upload
	  	openUploadModal(filesToUpload);
	}

	const removeDragData = (e) => {
		if (e.dataTransfer.items) { // Use DataTransferItemList interface to remove the drag data
		    e.dataTransfer.items.clear();
		} else { // Use DataTransfer interface to remove the drag data
			e.dataTransfer.clearData();
		}
	}

	const dragOverHandler = (e) => {
		e.preventDefault();

		// Prevent if modal opened or is root folder
		if ( uploadModalOpened ) {
			return;
		}

		setDroppingFiles(true);
	}

	const openUploadModal = (files) => {
		filesToUploadRef.current = files;
		setUploadModalOpened(true);
	}

	const closeUploadModal = () => {
		setUploadModalOpened(false);
		filesToUploadRef.current = [];
		openFolder(folder);
	}

	return (
		<DocumentsContainer
			onDrop={(e) => dropHandler(e)} 
			onDragOver={(e) => dragOverHandler(e)}
			isPanel={isPanel}
		>
			{ droppingFiles && 
				<DropCristal>
					Suelta los archivos aquí
					<div>
						<button className="btn btn-link" onClick={() => setDroppingFiles(false)}>Cancelar</button>
					</div>
				</DropCristal> 
			}

			{ uploadModalOpened && 
				<UploadModal 
					files={filesToUploadRef.current} 
					close={closeUploadModal} 
					folder={folder} 
					business={business}
				/> 
			}

			{ !isPanel &&
				<React.Fragment>
					<ButtonToggleTree className="btn btn-sm btn-primary text-white ms-auto d-block d-md-none" onClick={() => setTreeOpened(!treeOpened)}>
						<i className="bi bi-list"></i>
					</ButtonToggleTree>

					<TreeContainer opened={treeOpened} onClick={() => setTreeOpened(false)}>
						<Tree 
							folder={folder}
							openFolder={openFolder}
							reload={reloadTree}
							business={business}
							setBusiness={(c) => setBusiness(c)}
						/>
					</TreeContainer>
				</React.Fragment>
			}

			<Explorer
				folder={folder}
				rootFolderId={props.rootFolderId ?? -1}
				openFolder={openFolder}
				updateTree={() => setReloadTree(Math.random())}
				openUploadModal={openUploadModal}
				business={business}
				isPanel={isPanel}
				recipientsType={props.recipientsType}
				recipientsIds={props.recipientsIds}
				isFilePicker={isFilePicker}
			/>
		</DocumentsContainer>
	);
}


