import React, { useState } from "react";
import CustomInput from "components/CustomInput";
import LabeledFrame from "components/LabeledFrame";

export default function BusinessPanel(props) {
    let data = props.data;
    let setDataField = props.setDataField;
    let errors = props.errors;
    let canEdit = props.readOnly ? false : true;

    let [readOnly, setReadOnly] = useState(false);

    return (
        <LabeledFrame 
            label={
                <>
                    Empresa

                    { canEdit &&
                        <>
                            { readOnly ?
                                <button className="ms-2 p-0 btn btn-link" onClick={() => setReadOnly(false)}><i className="bi bi-check-lg"></i></button>
                                :
                                <button className="ms-2 p-0 btn btn-link" onClick={() => setReadOnly(true)}><i className="bi bi-pencil"></i></button>
                            }
                        </>
                    }
                </>   
            }
            className="mb-3 mb-md-0"
        >
            <div className="row">
                <div className="col col-static-250">
                    <div className="mb-2">
                        <CustomInput 
                            label="Razón social" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('business_name', e.target.value)} 
                            value={data?.business_name ?? ''} 
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.business_name'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.business_name'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-120">
                    <div className="mb-2">
                        <CustomInput 
                            label="CIF" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('vatnumber', e.target.value)} 
                            value={data?.vatnumber ?? ''} 
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.vatnumber'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.vatnumber'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-300">
                    <div className="mb-2">
                        <CustomInput 
                            label="Dirección" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('address', e.target.value)} 
                            value={data?.address ?? ''}
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.address'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.address'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-120">
                    <div className="mb-2">
                        <CustomInput 
                            label="C.P." 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('postalcode', e.target.value)} 
                            value={data?.postalcode ?? ''} 
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.postalcode'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.postalcode'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-200">
                    <div className="mb-2">
                        <CustomInput 
                            label="Ciudad" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('city', e.target.value)} 
                            value={data?.city ?? ''} 
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.city'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.city'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-180">
                    <div className="mb-2">
                        <CustomInput 
                            label="Provincia" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('province', e.target.value)} 
                            value={data?.province ?? ''} 
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.province'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.province'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-120">
                    <div className="mb-2">
                        <CustomInput 
                            label="País" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('country', e.target.value)} 
                            value={data?.country ?? ''} 
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.country'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.country'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-320">
                    <div className="mb-2">
                        <CustomInput 
                            label="Registro mercantil" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('commercial_registry', e.target.value)} 
                            value={data?.commercial_registry ?? ''} 
                            readOnly={!canEdit || !readOnly}
                        />
                        { errors['business_data.commercial_registry'] &&
                            <div className="invalid-feedback d-block">{ errors['business_data.commercial_registry'][0] }</div>
                        }
                    </div>
                </div>
            </div>	
        </LabeledFrame>
    );
}