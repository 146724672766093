import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, openPopupWindow, downloadFile } from 'helpers/generic';
import { getRouterBasename } from 'helpers/config';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getBusiness, authUserPermission } from 'helpers/business';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import Payments from './Payments';
import { default as ExpensesService } from 'services/Expenses';
import { default as ProvidersService } from 'services/Providers';
import { default as PaymentMethodsService } from 'services/PaymentMethods';
import { default as TradeCategoriesService } from 'services/TradeCategories';
import { default as FilesService } from 'services/Files';
// import { default as TaxesService } from 'services/Taxes';

const File = styled.div`
	position: relative;
	height: 30px;
	display: flex;
	align-items: center;
	padding-top: 10px;
	opacity: ${props => props.disabled ? 0.7 : 1};
	cursor: ${props => props.disabled ? '' : 'pointer'};
	font-size: 10px;
	line-height: 10px;

	.custom-input-label {
		position: absolute;
		top: 0;
		left: 5px;
		z-index: 2;
		font-size: 9px;
		color: var(--bs-primary);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	button {
		position: absolute;
		top: -10px;
		right: -5px;
		padding: 0;
		margin: 0;
		color: var(--bs-red);
		border: 0;
	}
`;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ExpensesForm() {
	const actionsDropdownRef = useRef(null);
	const fileRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();
	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				let method = await ExpensesService.get(params.id);
				if (method) {
					setData({...method});
					setCanEdit(false);
				} else {
					setData((prev) => ({...prev, id: null}));
				}

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data, 'expense');
		};
	}, [data]);

	const deleteExpense = async () => {
		const c = window.confirm('¿Quieres eliminar este gasto?');
		if ( !c ) return;

		let result = await ExpensesService.delete(data.id);
		if ( result ) {
			toast.success('Gasto borrado')

			if ( popup ) {
				data = null; // Set data to null to parentWindowProxyCallback
				window.close();
			} else navigate('/expenses');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await ExpensesService.save(data, getBusiness()?.id);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({...result.expense});

			if ( popup && goBack ) {
				if ( parentWindowProxyCallback ) data = result.expense; // Fix because parent needs id and update state function on previous line from this line update state async
				window.close();
				return;
			}

			if ( goBack ) navigate('/expenses');
			else {
		    	navigate('/expenses/edit/' + result.expense.id + (popup ? '?popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadProviders = async (input, callback) => {
		let providers = await ProvidersService.list({
			search: input,
			no_paginate: true
		});

		let formatted = providers?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const loadPaymentMethods = async (input, callback) => {
		let paymentMethods = await PaymentMethodsService.list({
			search: input,
			no_paginate: true
		});

		let formatted = paymentMethods?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const loadCategories = async (input, callback) => {
		let categories = await TradeCategoriesService.list('expenses', {
			search: input,
			no_paginate: true,
		});
		
		let formatted = categories?.map((el) => {
			return {
				value: el, 
				label: (
					<div style={{paddingLeft: ((el.level-1) * 8) + 'px'}}>
						{el.level > 1 &&
							<>
								<i className="bi bi-arrow-return-right"></i>
								&nbsp;
							</>
						}
						<span className="fw-bold text-dark me-1">{el.code}</span>
						{el.name}
					</div>
				)
			};
		});
		callback(formatted);
	}

	// const loadTaxes = async (input, callback) => {
	// 	let taxes = await TaxesService.list({
	// 		search: input,
	// 		no_paginate: true,
	// 	});

	// 	let formatted = taxes.map((el) => {
	// 		return {
	// 			value: el, 
	// 			label: <div>
	// 						<div>{el.name}</div>
	// 						<small>{el.percent}%</small>
	// 				   </div>
	// 		};
	// 	});
	// 	callback(formatted);
	// }

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const openFileInput = () => {
		if ( !canEdit ) return;
		
		setData({...data, file: null});
		fileRef.current.value = null;
		fileRef.current.click();
	}

	const fileInputChange = (e) => {
		let file = e.target.files[0];
		setData({...data, file: file});
	}

	const removeFile = (e) => {
		e.stopPropagation();
		setData({...data, file: 'delete'});
	}

	const viewFile = async (e, file) => {
		e.preventDefault();

		// If vieawable, open popup
		const baseUrl = getRouterBasename() + '/documents/view-file/';
		if ( file.viewable ) return openPopupWindow(baseUrl + file.id);

		// Not viewable, download
		let result = await FilesService.download(file.id);
		return downloadFile(result, file.name);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de gasto</h1>
					<button onClick={() => popup ? window.close() : navigate('/expenses')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className={'row ' + (popup ? 'justify-content-center mt-3' : '')}>
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col col-static-120">
											<div className="mb-2">
												<CustomInput label="Número" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('number', e.target.value)} value={data.number ?? ''} readOnly={!canEdit} />
												{ errors.number &&
													<div className="invalid-feedback d-block">{ errors.number[0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-150">
											<div className="mb-2">
												<CustomInput label="Fecha" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} readOnly={!canEdit} />
												{ errors.date &&
													<div className="invalid-feedback d-block">{ errors.date[0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-250">
											<div className="mb-2">
												<div className="input-group">
													<EmpoweredSelector
														load={loadProviders}
														onChange={(value) => setDataField('provider', value)}
														timeout={250}
														placeholder="Proveedor"
														showPlaceholderHelper={true}
														label={data.provider?.name}
														value={data.provider?.id}
														disabled={!canEdit} 
													/>
													<div className="input-group-append">
														<NavLink 
															className={'btn btn-light2 btn-sm text-secondary ' + (!canEdit ? 'disabled' : '')} 
															to={'/providers/add?popup=true'} 
															onClick={(e) => openPopupInfoWindow(e)} 
															onMouseDown={(e) => openPopupWindowMouseDownFix(e)}
														><i className="bi bi-plus-circle-fill"></i></NavLink>
													</div>
												</div>
												{ errors['provider.id'] &&
													<div className="invalid-feedback d-block">{ errors['provider.id'][0] }</div>
												}
											</div>
										</div>
										<div className="col col-static-250">
											<div className="mb-2">
												<div className="input-group">
													<EmpoweredSelector
														load={(input, callback) => loadPaymentMethods(input, callback)}
														onChange={(value) => setDataField('paymentmethod', value)}
														timeout={250}
														label={data.paymentmethod?.name ?? ''}
														placeholder="Forma de pago"
														showPlaceholderHelper={true}
														value={data.paymentmethod?.id}
														disabled={!canEdit} 
													/>
													<div className="input-group-append">
														<NavLink 
															className={'btn btn-light2 btn-sm text-secondary ' + (!canEdit ? 'disabled' : '')} 
															to={'/payment-methods/add?popup=true'} 
															onClick={(e) => openPopupInfoWindow(e)} 
															onMouseDown={(e) => openPopupWindowMouseDownFix(e)}
														><i className="bi bi-plus-circle-fill"></i></NavLink>
													</div>
												</div>
												{ errors['paymentmethod.id'] &&
													<div className="invalid-feedback d-block">{ errors['paymentmethod.id'][0] }</div>
												}
											</div>
										</div>	
										<div className="col col-static-250">
											<div className="mb-0">
												<div className="input-group">
													<EmpoweredSelector
														load={(input, callback) => loadCategories(input, callback)}
														onChange={(value) => setDataField('category', value)}
														timeout={250}
														label={
															data.category ?
																<div>
																	<b className="me-1">{data.category.code}</b>  
																	<span>{data.category.name}</span>
																</div>
																:
																null
														}
														placeholder="Categoría"
														showPlaceholderHelper={true}
														value={data.category?.id}
														disabled={!canEdit} 
													/>
													<div className="input-group-append">
													<NavLink 
															className={'btn btn-light2 btn-sm text-secondary ' + (!canEdit ? 'disabled' : '')} 
															to={'/trade-categories/incomes/add?popup=true'} 
															onClick={(e) => openPopupInfoWindow(e)} 
															onMouseDown={(e) => openPopupWindowMouseDownFix(e)}
														><i className="bi bi-plus-circle-fill"></i></NavLink>
													</div>
												</div>
												{ errors['category.id'] &&
													<div className="invalid-feedback d-block">{ errors['category.id'][0] }</div>
												}
											</div>
										</div>

										<div></div>

										<div className="col col-static-180">
											<div className="mb-2 mt-2">
												<CustomInput label="Base imponible" type="number" className="form-control form-control-sm" onChange={(e) => setDataField('base', e.target.value)} value={data.base ?? ''} readOnly={!canEdit} />
												{ errors.base &&
													<div className="invalid-feedback d-block">{ errors.base[0] }</div>
												}
											</div>
										</div>

										{/* <div className="col col-static-200">
											<div className="mb-2">
												<EmpoweredSelector
													load={loadTaxes}
													// onChange={(value) => addTaxToItem(idx, value)}
													timeout={250}
													placeholder="Añadir impuesto"
													showPlaceholderHelper={true}
												/>
												
												{ errors.taxes &&
													<div className="invalid-feedback d-block">{ errors.taxes[0] }</div>
												}
											</div>
										</div> */}

										<div className="col col-static-180">
											<div className="mb-2 mt-2">
												<CustomInput label="Total" type="number" className="form-control form-control-sm" onChange={(e) => setDataField('total', e.target.value)} value={data.total ?? ''} readOnly={!canEdit} />
												{ errors.total &&
													<div className="invalid-feedback d-block">{ errors.total[0] }</div>
												}
											</div>
										</div>

										<div className="col col-static-250">
											<div className="mb-2 mt-2">
												<File disabled={!canEdit} className="form-control form-control-sm" onClick={(e) => !data.file?.id ? openFileInput(e) : viewFile(e, data.file)}>
													<span className="custom-input-label">Archivo</span>
													{ data.file?.id && 
														<React.Fragment>
															<button disabled={!canEdit} className="btn btn-sm p-0" onClick={removeFile}><i className="bi bi-x-circle-fill"></i></button>
															<i className="bi bi-file-fill text-primary me-1"></i>
														</React.Fragment>
													}
													{ data.file ? data.file.name : 'Seleccionar archivo' }
												</File>
												{ errors.file &&
													<div className="invalid-feedback d-block">{ errors.file[0] }</div>
												}
												<input type="file" ref={fileRef} className="d-none" onChange={fileInputChange}  />
											</div>
										</div>

										<div className="col col-static-300">
											<div className="mb-2 mt-2">
												<CustomInput label="Observaciones" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''} readOnly={!canEdit} />
												{ errors.observations &&
													<div className="invalid-feedback d-block">{ errors.observations[0] }</div>
												}
											</div>
										</div>

										<div className="col-md-12"></div>
										
										{ data?.id && 
											<div className="col-md-6 mt-2">
												<Payments
													data={data}
												/>
											</div>
										}
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-4">
											{ ( data.id && authUserPermission('delete') ) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteExpense()}>Eliminar</button>							
											}
										</div>
										<div className="col-8 text-end">
											{ ((data.id && authUserPermission('edit')) || authUserPermission('add') ) &&
												<>
													<div className="dropdown d-inline">
														<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
															Acciones
														</button>
														<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
															<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
															<li><hr className="dropdown-divider" /></li>

															<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
															<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
														</ActionsContextMenu>
													</div>

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>					
												</>
											}		
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}