import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from "moment";
import CustomInput from 'components/CustomInput';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { default as  ReceiptsService } from "services/Receipts";
import { toast } from "react-toastify";

const ModalStyled = styled.div`

    & > button {
        padding: 0;
        line-height: 0;
        border: 0;
        background: transparent;
        color: var(--bs-primary);
    }

    .modal {
	    background: rgba(0, 0, 0, 0.4);

        .modal-dialog {
            max-width: 300px;
        }
    }

`;

export default function BulkGenerateReceiptsModal(props) {
	const modalRef = useRef(null);

    const selectedItems = props.selectedItems;
    const closeCallback = props.closeCallback;

    const [dueDate, setDueDate] = useState(moment().format('YYYY-MM-DD'));
    const [errors, setErrors] = useState(null);
    
    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, []);


    const closeModal = (redirect = false) => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();

        closeCallback(redirect);
    }

    const save = async () => {
        let invoicesIds = selectedItems.map(el => el.id);

        let result = await ReceiptsService.generateFromInvoice(invoicesIds, dueDate);
        
        if ( result.status ) {
            toast.info('Se han generado recibos desde las facturas seleccionadas');
            closeModal(true);
        } else {
            setErrors(result.errors);
            toast.error('Ha ocurrido un error al generar los recibos.');
        }

    }

	return (
        <ModalStyled>
            <div className="modal" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Generar recibos</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-0">
                                        <CustomInput label="Fecha de vencimiento" type="date" className="form-control form-control-sm" value={dueDate ?? ''} onChange={(e) => setDueDate(e.target.value)} />
                                    </div>
                                </div>
                                {errors && 
                                    <div className="col-md-12 mt-3">
                                        <ul>
                                            {Object.keys(errors).map((field, idx) =>
                                                errors[field].map((errorMsg, i) => (
                                                    <li key={`${idx}-${i}`} className="text-danger" style={{lineHeight: 1}}>
                                                        <small>{errorMsg}</small>
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={'btn btn-sm btn-primary text-white ' + (!dueDate ? 'disabled' : '')} onClick={() => save()}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
	);
}