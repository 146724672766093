import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from 'layouts/PopupLayout';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { loader, formatNumber, openPopupWindow, downloadLocalFile } from 'helpers/generic';
import { getBusiness, authUserPermission } from 'helpers/business';
import BusinessChecker from 'components/BusinessChecker';
import { default as BankRemittancesService } from 'services/BankRemittances';
import CopyBankRemittanceModal from './CopyBankRemittanceModal';

const Table = styled.table`
	& > thead > tr > th, 
	& > tbody > tr > td {
		&:nth-child(1) {
			width: 20px;
		}

		&:nth-child(2) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(3) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(5) {
			width: 50px;
			text-align: right;
		}

		&:nth-child(6) {
			width: 50px;
			white-space: nowrap;
			text-align: right;
		}
		
		@media (max-width: 768px) {
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				display: none;
			}
		}
	}

	tbody {
		tr.details {
			border-bottom: 2px solid var(--bs-primary);
		}
	}
`;

const TableReceipts = styled.table`
	font-size: 13px;

	tbody {
		border-top: 0 !important;
	}

	td, th {
		&:nth-child(1) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(2) {
			width: 220px;
		}

		&:nth-child(4) {
			width: 80px;
			white-space: nowrap;
			text-align: right;
		}

		&:nth-child(5) {
			width: 50px;
			vertical-align: middle;

			button {
				margin-left: 10px;
			}
		}
	}
`;

export default function BankRemittances() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const id = queryParams.get('id');

	let [forceReload, setForceReload] = useState(null);
	let [forceReloadOnlyList, setForceReloadOnlyList] = useState(null);
	let [remittances, setRemittances] = useState({});
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [details, setDetails] = useState({});
	let [fileGenerationError, setFileGenerationError] = useState(null);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('number');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setRemittances({...remittances, data: undefined});
		_setPage(page);
	}
	let [copyRemittanceModalOpened, setCopyRemittanceModalOpened] = useState(null);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);

			// Remove details
			delete details[rowId];
			setDetails({...details});
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const deleteBankRemittance = async (remittance_id) => {
		const c = window.confirm('¿Seguro que quieres eliminar esta remesa?');
		if ( !c ) return false;

		let result = await BankRemittancesService.delete(remittance_id);
		if ( result ) {
			toast.info('Remesa bancaria eliminada');
			setDetails((prev) => {
				delete prev[remittance_id];
				return {...prev};
			});
			setForceReloadOnlyList(Math.random());
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const downloadBankRemittanceFile = async (remittance) => {
		setFileGenerationError(null);

		let result = await BankRemittancesService.downloadXml(remittance.id);

		if ( result.status ) {
			downloadLocalFile(result.data, 'fichero-banco-remesa-' + remittance.number_full + '.xml');
		} else {
			if ( result.data ) {
				setFileGenerationError(result.data);
				toast.error('No se ha podido generar el fichero');
			}
		}
	}

	const toggleCompleted = async (id, status) => {
		let result = await BankRemittancesService.toggleCompleted(id, status);

		if ( result.status ) {
			let newState = details[id];
			newState.completed_at = status ? 1 : 0;
			setDetails((prev) => ({...prev, [id]: newState}));
		}
	}

	const unlinkReceipt = async (remittance, receipt) => {
		const c = window.confirm('¿Seguro que quieres quitar el recibo ' + receipt.number_full + ' de la remesa ' + remittance.number_full + '?');
		if ( !c ) return;

		let result = await BankRemittancesService.unlinkReceipt(remittance.id, receipt.id);
		if ( result?.status ) {
			toast.info('Recibo quitado');
			setForceReload(Math.random());
		} else {
			toast.error('No se ha podido quitar el recibo');
		}
	}

	useEffect(() => {
		const getRemittances = async () => {	
			let remittances = await BankRemittancesService.list({
				business_id: getBusiness()?.id,
				page: page,
				sort: sortField,
				direction: sortDirection,
				id: id
			});

			setRemittances({...remittances});
			setSkeletonRows(remittances.data.length);
		}

		getRemittances();
	}, [forceReload, forceReloadOnlyList, page, sortField, sortDirection, id]);

	useEffect(() => {
		// Loop and load details if empty
		detailsOpened.forEach(async (id) => {
			if ( !id ) return;

			let remittance = await BankRemittancesService.get(id);
			setDetails((prev) => ({...prev, [id]: {...remittance}}));
		});
	}, [detailsOpened, forceReload]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	useEffect(() => {
		if ( remittances.data && remittances.data.length > 0 ) {
			toggleDetails(parseInt(id));
		}
	}, [remittances.data, id]);

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BusinessChecker />
			<section>
				<div className="page-title">
					<h1>Remesas bancarias</h1>
					
					{/* { (authUserPermission('add') && !popup) &&
						<NavLink to="/bankremittances/new" className="btn btn-sm btn-light ms-auto">Realizar remesa</NavLink>
					} */}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">

						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
								<div className="col-md-3">
									
								</div>
								<div className="col-md-6 text-end">
									{/* <button className="btn btn-sm btn-light me-2" onClick={() => setShowExportModal(true)}>Exportar</button> */}
								</div>
							</div>
						</div>

						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'number_full'} onClick={() => sortTableClick('number_full')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'payment_date'} onClick={() => sortTableClick('payment_date')}>Fecha de pago</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'treasury.name'} onClick={() => sortTableClick('treasury.name')}>Banco</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'receipts.count'} onClick={() => sortTableClick('receipts.count')}>Recibos</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'receipts.total'} onClick={() => sortTableClick('receipts.total')}>Total</ThSortable>
										</tr>
									</thead>
									<tbody>
										{ remittances.data?.length > 0 &&
											remittances.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>{el.number_full}</td>
															<td>{moment(el.date).format('DD-MM-YYYY')}</td>
															<td>{moment(el.payment_date).format('DD-MM-YYYY')}</td>
															<td>
																<div>{el.treasury_data?.name}</div>
																<small>{el.treasury_data?.data?.iban}</small>
															</td>
															<td>{el.receipts_count}</td>
															<td>{el.total} €</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		{ !details[el.id] && 
																			<div className="col-md-12 text-center">
																				{loader}
																			</div>
																		}

																		{ details[el.id] && 
																			<React.Fragment>
																				<div className="col-md-4">
																					<div><b className="sbold">Fecha</b>: {moment(el.date).format('DD-MM-YYYY')}</div>
																					<div><b className="sbold">Fecha de pago</b>: {moment(el.payment_date).format('DD-MM-YYYY')}</div>
																					<div>
																						<b className="sbold">Banco</b>: 
																						&nbsp;{el.treasury_data?.name ?? '-'} <small>{el.treasury_data?.data?.iban ?? ''}</small>&nbsp;
																						<NavLink to={'/treasury/edit/' + el.treasury.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
																					</div>
																					<div><b className="sbold">Concepto</b>: {el.concept ?? '-'}</div>
																				</div>
																				<div className="col-md-4">
																					<div><b className="sbold">Nº de recibos</b>: {el.receipts_count}</div>
																					<div><b className="sbold">Total</b>: {formatNumber(el.total)} €</div>
																				</div>
																				<div className="col-md-4 text-end">
																					{ fileGenerationError &&
																						<div className="text-danger small text-start">{fileGenerationError}</div>
																					}
																					<button className="btn btn-sm btn-primary text-white mb-3" onClick={() => downloadBankRemittanceFile(el)}><small>Descargar fichero banco</small></button>																					
																					<div></div>
																					{ authUserPermission('edit') &&
																						<div className="form-check form-switch mb-3 d-inline-flex align-items-center">
																							<input className="form-check-input mb-1 me-2" type="checkbox" checked={details[el.id].completed_at ? true : false} onChange={(e) => toggleCompleted(el.id, e.target.checked)} />
																							<label className="form-check-label">Enviada al banco</label>
																						</div>
																					}
																					<div></div>
																					{(!details[el.id].is_used && authUserPermission('delete')) &&
																						<div><button className="btn btn-sm btn-link text-danger" onClick={() => deleteBankRemittance(el.id)}><small>Eliminar remesa</small></button></div>
																					}
																					{ authUserPermission('add') &&
																						<div><button className="btn btn-sm btn-link text-primary" onClick={() => setCopyRemittanceModalOpened(el)}><small>Copiar remesa</small></button></div>
																					}
																					</div>
																				<div className="col-md-12 mt-3">
																					<div className="table-responsive">
																						<TableReceipts className="table table-sm table-bordered mb-2">
																							<thead>
																								<tr>
																									<th>Nº</th>
																									<th>Cuenta bancaria</th>
																									<th>Cliente</th>
																									<th>Total</th>
																									<th></th>
																								</tr>
																							</thead>
																							<tbody>
																								{details[el.id]?.receipts?.map((rEl, rIdx) => {
																									return (
																										<tr key={'r'+rIdx}>
																											<td>{rEl.number_full}</td>
																											<td>
																												{rEl.bankaccount_data?.iban}

																												{ rEl.refunded_at &&
																													<>
																														<div className="text-danger small">
																															DEVUELTO <i className="bi bi-dash-circle-fill"></i>

																															{ rEl.parent ?
																																<span>
																																	&nbsp;<i className="bi bi-arrow-right"></i> {rEl.parent.number_full}
																																</span>
																																:
																																<span>
																																	&nbsp; nuevo recibo no generado
																																</span>
																															}
																														</div>
																													</>
																												}
																											</td>
																											<td>{rEl.client_data?.name}</td>
																											<td>{formatNumber(rEl.amount)} €</td>
																											<td className="text-center">
																												<NavLink to={'/receipts/edit/' + rEl.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-pencil"></i></NavLink>
																												{(!el.completed_at && authUserPermission('edit')) &&
																													<button className="btn btn-link p-0 text-danger" onClick={() => unlinkReceipt(el, rEl)}><i className="bi bi-x"></i></button>
																												}
																											</td>
																										</tr>
																									);
																								})}
																								{ details[el.id]?.receipts?.length === 0 &&
																									<tr>
																										<td colSpan="100%" className="text-start">
																											No hay recibos
																										</td>
																									</tr>
																								}
																							</tbody>
																						</TableReceipts>
																					</div>
																				</div>
																			</React.Fragment>
																		}
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ remittances.data && !remittances.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ remittances.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end" id={popup ? 'footer-fixed' : ''}>
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={remittances?.current_page}
									max={remittances?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ copyRemittanceModalOpened &&
				<CopyBankRemittanceModal
					remittance={copyRemittanceModalOpened}
					closeCallback={() => {
						setForceReload(Math.random());
						setCopyRemittanceModalOpened(false);
					}}
				/>
			}
		</Layout>
	);
}


