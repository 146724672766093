import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import TreeRow from './TreeRow';
import FolderModal from './FolderModal';
import { default as FilesService } from 'services/Files';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

export default function CopyMoveModal(props) {
	const modalRef = useRef(null);

	const [folder, setFolder] = useState(null);

	const business = {
		id: props.file ? props.file.business?.id : props.business?.id,
		name: props.file ? props.file.business?.name : props.business?.name,
		folder_id: props.file ? props.file.business?.folder_id : props.business?.folder_id,
	};

	const [selectedFolderId, setSelectedFolderId] = useState(null);
	const [createFolderModal, setCreateFolderModal] = useState(false);
	const [forceReload, setForceReload] = useState(false);

	const files = props.files;
	const action = props.action;
	const closeCallback = props.closeCallback;

	useEffect(() => {
		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();
	}, [closeCallback]);

	const openCreateFolderModal = () => {
		setCreateFolderModal(true);
	}

	const doAction = async () => {
		for await (const file of files) {
			if ( action === 'move' ) {
				await FilesService.move(file.id, folder.id);
			}
	
			if ( action === 'copy' ) {
				await FilesService.copy(file.id, folder.id);
			}
		}

		closeCallback(files.length > 0 ? true : false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{action === 'move' ? 'Mover' : 'Copiar'} documento</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<TreeRow 
							businessFolderTreeEnabled={true}
							folder={
								business?.id ?
								{
									id: business.folder_id,
									name: business.name,
									code: 'business',
									has_content: true
								}
								:
								{
									id: 			-1,
									name: 			'Documentos',
									code: 			'documents',
									has_content: 	true
								}
							}
							openFolder={(data) => {
								setFolder(data);
								setSelectedFolderId(data?.id ?? null);
							}}
							selectedFolderId={selectedFolderId}
							business={business}
							level={0}
							reload={forceReload}
						/>
					</div>
					<div className="modal-footer justify-content-between">
						<button type="button" className="btn btn-sm btn-secondary text-white" disabled={!folder} onClick={() => openCreateFolderModal()}>Nueva carpeta</button>
						<button type="button" className="btn btn-sm btn-primary text-white" disabled={!folder} onClick={() => doAction()}>{action === 'move' ? 'Mover' : 'Guardar'}</button>
					</div>
				</div>
			</div>

			{createFolderModal &&
				<FolderModal 
					closeCallback={() => {
						setCreateFolderModal(false);
						setForceReload(Math.random());
					}}
					folder={{}}
					parentFolder={folder}
					business={business}
				/>
			}
		</ModalStyled>
	);
}


