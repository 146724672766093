import styled from 'styled-components';
import { default as DocumentsComponent } from 'components/Documents/Documents';
import PopupLayout from 'layouts/PopupLayout';
import { useSearchParams } from "react-router-dom";

const DocumentsPageContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export default function Documents(props) {
	const [queryParams] = useSearchParams();

	const folder_id = queryParams.get('folder_id') ?? undefined;
	const file_picker = queryParams.get('file_picker') === 'true';

	return (
		<PopupLayout>
			<DocumentsPageContainer>
				<DocumentsComponent 
					rootFolderId={folder_id} 
					panel={folder_id ? true : false}
					isFilePicker={file_picker}
				/>
			</DocumentsPageContainer>
		</PopupLayout>
	);
}


