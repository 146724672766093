import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { formatNumber, loader } from 'helpers/generic';
import { default as BankReconciliationService } from 'services/BankReconciliation';
import { default as ProviderInvoicesService } from 'services/ProviderInvoices';
import { default as TradeCategoriesService } from 'services/TradeCategories';
import EmpoweredSelector from 'components/EmpoweredSelector';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const TableInvoice = styled.table`
    font-size: 12px;
    
    thead {
        tr {
            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            width: 100px;
            text-align: right;
        }
    }
`;

const EmpoweredSelectorCustom = styled.div`
    max-width: 300px;

    .empowered-selector {

        .dropdown-item {

            &:has(.no-clickable) {

                &:active,
                &:hover {
                    background: white;
                    color: unset;
                    cursor: not-allowed;
                }
            }

            .no-clickable {
                position: relative;

                &:hover {
                    cursor: not-allowed;
                    div.info {
                        display: inline-block;
                    }
                }

                div.info {
                    display: none;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: white;
                    padding: 3px;
                    border: 1px solid var(--bs-danger);
                    border-radius: 5px;
                    font-size: 9px;
                    color: var(--bs-danger);
                    z-index: 1000;
                }
            }
        }
    }
`;

export default function ProviderInvoice(props) {
    const hideModal = props.hideModal;

    const [provider, setProvider] = useState(undefined);
    const [invoice, setInvoice] = useState(undefined);
    const [category, setCategory] = useState(null);
    const entity = props.movements.filter((item) => item.class.indexOf('ProviderInvoice') !== -1)[0] ?? null; 

    const noCategory = invoice && !invoice?.category_id ? true : false;
    const btnDisabled = noCategory && !category?.id;

    useEffect(() => {
        let getInvoice = async () => {
            let result = await ProviderInvoicesService.get(entity.id);
            if ( result ) {
                setInvoice({...result});
                setProvider({...result.provider});
            }
        }
        getInvoice();
    }, [entity]);

    const loadCategories = async (input, callback) => {
		let categories = await TradeCategoriesService.list('expenses', {
			search: input,
			no_paginate: true,
            childrens_count: true
		});
		
		let formatted = categories?.map((el) => {
			return {
				value: el, 
				label: (
					<div 
						style={{paddingLeft: ((el.level-1) * 8) + 'px'}}
						onClick={(e) => {
                            if ( el.children_count > 0 ) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        className={el.children_count > 0 ? 'no-clickable' : ''}
					>
						{el.level > 1 &&
							<>
								<i className="bi bi-arrow-return-right"></i>
								&nbsp;
							</>
						}
                    	<span className="fw-bold text-dark me-1">{el.code}</span>
						{el.name}
					</div>
				)
			};
		});
		callback(formatted);
	}

    const saveData = async () => {
        let result = await BankReconciliationService.save('provider-invoice', props.movements, {category_id: category?.id ?? null});
        if ( result.status === 1 ) {
            toast.success('Movimientos conciliados');
            hideModal(true);
        } else {
            toast.error('Ha ocurrido un error al guardar');
        }
    }

    let bankMovements = props.movements.filter((item, idx) => item.class.indexOf('ProviderInvoice') === -1);
    let totalBankMovements = bankMovements.reduce((acc, item) => acc + item.amount, 0);

	return (
		<div className="row">
            { invoice?.id &&   
                <React.Fragment>
                    <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="sbold">Proveedor</label>
                            <div className="small">{provider?.name}</div>
                        </div>
                    </div>
                
                    <div className="col-md-12 mb-3">
                        <label className="sbold">Movimientos bancarios a conciliar</label>
                        <TableMovements className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Concepto</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankMovements.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                            <td>{item.concept ?? ''}</td>
                                            <td>{formatNumber(item.amount ?? 0)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                { bankMovements.length > 1 &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="sbold">{formatNumber(totalBankMovements, 2)}</td>
                                    </tr>
                                }
                                { Math.abs(totalBankMovements) < invoice.total &&
                                    <tr>
                                        <td colSpan="100%" className="text-center text-danger">Parcialmente pagada</td>
                                    </tr>
                                }
                            </tfoot>
                        </TableMovements>
                    </div>
                
                    <div className="col-md-12">
                        <label className="sbold">Factura recibida a conciliar</label>
                        <TableInvoice className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Nº</th>
                                    <th>Importe</th>
                                    <th>Pendiente</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{invoice.date ? moment(invoice?.date).format('DD-MM-YYYY') : '-'}</td>
                                    <td>{invoice?.number ?? '-'}</td>
                                    <td>{formatNumber(invoice.total ?? 0)}</td>
                                    <td>{formatNumber(invoice.pending ?? 0)}</td>
                                </tr>
                            </tbody>
                        </TableInvoice>
                    </div>

                    { noCategory &&
                        <div className="col-md-12">
                            <label className="sbold">Seleccionar una categoría para la factura</label>
                            <EmpoweredSelectorCustom>
                                <EmpoweredSelector
                                    load={(input, callback) => loadCategories(input, callback)}
                                    onChange={(value) => setCategory(value)}
                                    timeout={250}
                                    label={
                                        category ?
                                            <div>
                                                <b className="me-1">{category.code}</b>  
                                                <span>{category.name}</span>
                                            </div>
                                            :
                                            null
                                    }
                                    placeholder="Categoría"
                                    value={category?.id}
                                />
                            </EmpoweredSelectorCustom>
                        </div>
                    }
                </React.Fragment>
            }

            { (invoice === undefined) && <div className="col-md-12"><div style={{width: '200px', margin: '0 auto'}} className="text-center">{loader}</div></div> }

            <div className="text-end mt-3">
				<button type="button" className="btn btn-primary text-white" onClick={() => saveData()} disabled={btnDisabled}>Confirmar conciliación</button>
            </div>
        </div>
	);
}


