import useStore from "store";

export const debugMode = () => {
	return useStore.getState().debug;
}

export const getConfig = () => {
	return useStore.getState().config;
}
 
export function setConfig(data) {
	useStore.getState().dispatch({
		type: 'setConfig',
		data: data
	});
}

export function getRouterBasename() {
	return process.env.PUBLIC_URL;
}

export function getAjaxUrl() {
	return debugMode() ? 'http://127.0.0.1:8000' : '/'; 
}