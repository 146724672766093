import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getUser, setUser } from 'helpers/user';
import { default as AuthService } from 'services/Auth';

export default function Logout() {

    useEffect(() => {
    	const logout = async () => {
			await AuthService.logout();
    		setUser(null, true);
	    }
	    logout();
    }, []);

	return (
		<React.Fragment>
			{ 
				getUser() ?
					<div className="text-center"> 
						Cerrando sesión...
					</div>
				:
				<Navigate to="/" />
			}
		
		</React.Fragment>
	)
}