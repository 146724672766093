import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { formatNumber, loader } from 'helpers/generic';
import { default as BankReconciliationService } from 'services/BankReconciliation';
import { default as ExpensesService } from 'services/Expenses';

const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }
    }
`;

const TableExpense = styled.table`
    font-size: 12px;
    
    thead {
        tr {
            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            width: 100px;
            text-align: right;
        }
    }
`;

export default function Expense(props) {
    let hideModal = props.hideModal;

    let [provider, setProvider] = useState(undefined);
    let [expense, setExpense] = useState(undefined);
    let entity = props.movements.filter((item) => item.class.indexOf('Expense') !== -1)[0] ?? null; 

    useEffect(() => {
        let getExpense = async () => {
            let result = await ExpensesService.get(entity.id);
            if ( result ) {
                setExpense({...result});
                setProvider({...result.provider});
            }
        }
        getExpense();
    }, [entity]);

    const saveData = async () => {
        let result = await BankReconciliationService.save('expense', props.movements);
        if ( result.status === 1 ) {
            toast.success('Movimientos conciliados');
            hideModal(true);
        } else {
            toast.error('Ha ocurrido un error al guardar');
        }
    }

    let bankMovements = props.movements.filter((item, idx) => item.class.indexOf('Expense') === -1);
    let totalBankMovements = bankMovements.reduce((acc, item) => acc + item.amount, 0);

	return (
		<div className="row">
            { expense?.id &&   
                <React.Fragment>
                    <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="sbold">Proveedor</label>
                            <div className="small">{provider?.name}</div>
                        </div>
                    </div>
                
                    <div className="col-md-12 mb-3">
                        <label className="sbold">Movimientos bancarios a conciliar</label>
                        <TableMovements className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Concepto</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankMovements.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                            <td>{item.concept ?? ''}</td>
                                            <td>{formatNumber(item.amount ?? 0)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                { bankMovements.length > 1 &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="sbold">{formatNumber(totalBankMovements, 2)}</td>
                                    </tr>
                                }
                                { Math.abs(totalBankMovements) < expense.total &&
                                    <tr>
                                        <td colSpan="100%" className="text-center text-danger">Parcialmente pagada</td>
                                    </tr>
                                }
                            </tfoot>
                        </TableMovements>
                    </div>
                
                    <div className="col-md-12">
                        <label className="sbold">Gasto a conciliar</label>
                        <TableExpense className="table table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Nº</th>
                                    <th>Importe</th>
                                    <th>Pendiente</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{expense.date ? moment(expense?.date).format('DD-MM-YYYY') : '-'}</td>
                                    <td>{expense?.number ?? '-'}</td>
                                    <td>{formatNumber(expense.total ?? 0)}</td>
                                    <td>{formatNumber(expense.pending ?? 0)}</td>
                                </tr>
                            </tbody>
                        </TableExpense>
                    </div>
                </React.Fragment>
            }

            { (expense === undefined) && <div className="col-md-12"><div style={{width: '200px', margin: '0 auto'}} className="text-center">{loader}</div></div> }

            <div className="text-end mt-3">
				<button type="button" className="btn btn-primary text-white" onClick={() => saveData()}>Confirmar conciliación</button>
            </div>
        </div>
	);
}


