import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader } from 'helpers/generic';
import { authUserPermission } from 'helpers/business';
import ClientPanel from './ClientPanel';
import BankAccountPanel from './BankAccountPanel';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import { default as ReceiptsService } from 'services/Receipts';
import { default as InvoicesService } from 'services/Invoices';

// Get proxy callback from parent opener to make updates if possible
let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ReceiptForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const forceCanEdit = queryParams.get('edit') === 'true';
	const copyFromId = queryParams.get('copy_from_id');
	const fromInvoiceId = queryParams.get('from_invoice_id');

	let [data, setData] = useState({
		due_date: moment().format('YYYY-MM-DD'),
		amount: 0,
		is_editable: true
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);
	let [copyFromIdInvoiceIdFix, setCopyFromIdInvoiceIdFix] = useState(false);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data);
		};
	}, [data]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = (data, entity) => {
			if ( entity === 'client' ) selectClient(data);
		}
	}, []);

	useEffect(() => {
		// Get receipt or copy
		if ( params.id || copyFromId ) {
			const getData = async () => {
				setLoading(true);

				let id = null;
				if ( params.id ) id = params.id;
				if ( copyFromId ) id = copyFromId;
				
				let receipt = await ReceiptsService.get(id, null);
				if ( receipt ) {
					let dataToSet = {
						...receipt,
						client: {...receipt.client_data},
						bankaccount: {...receipt.bankaccount_data}
					}

					// Copy from another receipt
					if ( copyFromId ) {
						if ( dataToSet.invoice_id ) setCopyFromIdInvoiceIdFix(dataToSet.invoice_id); // We need this ID to show a checkbox asking if generate invoice

						dataToSet.id = null;
						dataToSet.due_date = moment().format('YYYY-MM-DD');
						dataToSet.number = null;
						dataToSet.number_full = null;
						dataToSet.invoice_id = null;
					}


					if ( !forceCanEdit ) {
						setCanEdit(false);
					} else {
						dataToSet.is_editable = true;
					}

					setData(dataToSet);
				}

				setLoading(false);
			}
			getData();
		}

		// Generate data from invoice
		const getDataFromInvoice = async () => {
			let invoice = await InvoicesService.get(fromInvoiceId);
			if ( invoice ) {
				let dataToSet = {
					id: null,
					due_date: moment().format('YYYY-MM-DD'),
					client_id: invoice.client_id,
					client_data: {
						id: invoice.client_id,
						name: invoice.client_data?.name,
						vatnumber: invoice.client_data?.vatnumber
					},
					amount: invoice.total,
					concept: 'Factura ' + invoice.number_full,
					invoice_id: invoice.id,
					is_editable: true
				}
				setData(dataToSet);
			} 
		}
		if ( fromInvoiceId ) getDataFromInvoice();
	}, [params.id, copyFromId, fromInvoiceId, forceCanEdit]);

	// Generate receipt number
	useEffect(() => {
		if ( data.number ) return;

		let generateNumber = async () => {
			let nextNumber = await ReceiptsService.getNextReceiptNumber(data.due_date);
			if ( nextNumber ) {
				setData((prev) => ({...prev, number: nextNumber.number, number_full: nextNumber.number_full}));	
			}
		}
		generateNumber();

	}, [data.number, data.due_date]);
 
	const deleteReceipt = async () => {
		const c = window.confirm('¿Quieres eliminar este recibo?');
		if ( !c ) return;

		let result = await ReceiptsService.delete(data.id);
		if ( result ) {
			toast.info('Recibo borrado')

			if ( popup ) window.close();
			else navigate('/receipts');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await ReceiptsService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({
				...result.receipt,
				client: {...result.receipt.client_data},
				bankaccount: {...result.receipt.bankaccount_data}
			});
			
			if ( goBack ) {
				if ( popup ) {
					data = result.receipt; // Fix: prevent send incompleted data when popup is closed. Not a good way, but it solves the problem.
					window.close();
				} else navigate('/receipts');
			} else if ( !data.id ) {
		    	navigate('/receipts/edit/' + result.receipt.id + '?edit=true' +  (popup ? '&popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}

		setLoading(false);
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const selectClient = (client) => {
		setData((prev) => ({
			...prev, 
			client_id: client?.id,
			client_data: {...client}
		}));
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Recibos | {(!data.id && !copyFromId) ? 'Nueva' : ''} {data.id ? 'Ficha' : ''} {copyFromId ? 'Copia' : ''}</h1>
					<button onClick={() => popup ? window.close() : navigate('/receipts')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<LabeledFrame 
												label="Datos básicos" 
												labelSecondary={data.number_full ? <span className="text-white fw-bold">{data.number_full}</span> : null}
												labelSecondaryBackground="primary" 
												className="mb-3 mb-md-0"
											>
												<div className="row">
													<div className="col col-static-140">
														<div className="mb-2">
															<CustomInput 
																label="Fecha vencimiento" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('due_date', e.target.value)} 
																value={data.due_date ?? ''} 
																readOnly={!canEdit || !data.is_editable} 
															/>
															{ errors.due_date &&
																<div className="invalid-feedback d-block">{ errors.due_date[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-140">
														<div className="mb-2">
															<CustomInput 
																label="Importe" 
																type="number" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('amount', e.target.value)} 
																value={data.amount ?? ''} 
																readOnly={!canEdit || !data.is_editable} 
															/>
															{ errors.amount &&
																<div className="invalid-feedback d-block">{ errors.amount[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-310">
														<div className="mb-2">
															<CustomInput 
																label="Concepto" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('concept', e.target.value)} 
																value={data.concept ?? ''} 
																readOnly={!canEdit || !data.is_editable} 
															/>
															{ errors.concept &&
																<div className="invalid-feedback d-block">{ errors.concept[0] }</div>
															}
														</div>
													</div>
													{ (canEdit && !data.is_editable) &&
														<div className="invalid-feedback d-block">El recibo no se puede modificar por que ya ha sido remesado y la remesa ha sido enviada al banco</div>
													}	
													{(copyFromId && copyFromIdInvoiceIdFix) && 
														<div className="col col-static-350">
															<div className="form-check d-flex align-items-center mb-0" style={{lineHeight: '100%'}}>
																<input 
																	className="form-check-input me-2" 
																	style={{width: '20px'}}
																	type="checkbox" 
																	id="generate-invoice" 
																	checked={data.generate_invoice_from_id ? true : false} 
																	onChange={(e) => setDataField('generate_invoice_from_id', e.target.checked ? copyFromIdInvoiceIdFix : null)} 
																/>
																<label className="form-check-label mt-1" htmlFor="generate-invoice">
																	Se ha detectado una factura asociada al recibo que quieres copiar. ¿Quieres generar una factura para el nuevo recibo?
																</label>
															</div>
														</div>
													}
												</div>	
											</LabeledFrame>
										</div>
										<div className="col-md-6">
											<ClientPanel 
												data={data.client_data}

												setData={(client) => {
													setData({
														...data,
														client_id: client?.id,
														client_data: {...client}
													})
												}}

												setDataField={(field, value) => {
													setData({
														...data, 
														client_data: {
															...data.client_data,
															[field]: value
														}
													});
												}}

												errors={errors}
												readOnly={canEdit && data.is_editable ? false : true}
											/>
										</div>
										<div className="col-md-6 mt-3">
											<BankAccountPanel 
												clientId={data.client_id}

												data={data.bankaccount_data}

												setData={(bankaccount) => {
													setData({
														...data,
														bankaccount_id: bankaccount?.id,
														bankaccount_data: {...bankaccount}
													})
												}}

												setDataField={(field, value) => {
													setData({
														...data, 
														bankaccount_data: {
															...data.bankaccount_data,
															[field]: value
														}
													});
												}}

												errors={errors}
												readOnly={canEdit && data.is_editable ? false : true}
											/>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && data.is_deletable && authUserPermission('delete')) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteReceipt()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add') && !data.id) || (authUserPermission('edit') && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<>
															<div className="dropdown d-inline">
																<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																	Acciones
																</button>
																<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																	<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>					
																	<li><hr className="dropdown-divider" /></li>
																	<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																	<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
																</ActionsContextMenu>
															</div>
														</>
													}

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}