import React, { useState, useEffect } from 'react';
import { useSearchParams, NavLink } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import TrSkeleton from 'components/TrSkeleton';
import { formatNumber, loader, jsonToArray } from 'helpers/generic';
import BusinessChecker from 'components/BusinessChecker';
import {default as TradeCategoriesService} from 'services/TradeCategories';

const Table = styled.table`
	th,td {
		font-size: 14px;
		vertical-align: middle;

		&:last-of-type {
			width: 50px;
		}

		&:nth-child(1) {
			b {
				font-weight: 600;
				margin-right: 5px;
				font-size: 12px;
				line-height: 14px;
			}
		}

		&:nth-child(2), 
		&:nth-child(3), 
		&:nth-child(4) {
			text-align: right;
			width: 150px;

			img {
				height: 20px;
			}
		}
	}

	tr {

		&.highlight {
			
			font-weight: 600;
			background: var(--bs-light);
		}
	}
`;

export default function TradeCategories(props) {
	const type = props.type;
	const typeName = props.type === 'incomes' ? 'ingresos' : 'gastos';

	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const addCallback = queryParams.get('add_callback') === 'true';

	let [financialYears, setFinancialYears] = useState([]);
	let [financialYear, setFinancialYear] = useState(moment().format('YYYY'));
	let [categories, setCategories] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [balances, setBalances] = useState({});

	useEffect(() => {
		const getDataAsync = async() => {
			setCategories(undefined);
			setBalances({});

			// Categories
			let categories = await TradeCategoriesService.listOrdered(type, {
				financial_year: financialYear,
				children_count: true
			});
			setCategories([...categories]);
			setSkeletonRows(categories?.length);

			// If addcallback, stop here
			if ( addCallback ) return;

			// Financial Years
			let financialYearsList = await TradeCategoriesService.financialYearsList(type);
			if ( financialYearsList ) setFinancialYears(financialYearsList);

			// Get balances
			let balances = await TradeCategoriesService.getBalancesBulk(type, {year: financialYear});
			setBalances(balances);
		}
		getDataAsync();
	}, [type, financialYear, addCallback]);

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BusinessChecker />

			<section>
				<div className="page-title">
					<h1>Categorías de {typeName}</h1>

					{!addCallback &&
						<NavLink to={'/trade-categories/' + type+ '/add?hide_parent_field=true' + (popup ? '&popup=true' : '') + (addCallback ? '&add_callback=true' : '')} className="btn btn-sm btn-light ms-auto">Nueva categoría</NavLink>				
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						{!addCallback &&
							<div className="card-header bg-white p-3">
								<div className="row">
									<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
										<select className="form-control form-control-sm" value={financialYear ?? ''} onChange={(e) => setFinancialYear(e.target.value)}>
											<option disabled>- Ejercicios -</option>
											{financialYears.map((el, idx) => {
												return (<option key={idx} value={el}>{el}</option>);
											})}
										</select>
									</div>
									<div className="col-md-7 mb-2 mt-2 mb-md-0 mt-md-0">
										
									</div>
									<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
										{/* <NavLink className="btn btn-sm btn-light" to="/accounting-entries">Ver asientos contables</NavLink> */}
									</div>
								</div>
							</div>
						}
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className={'table ' + (!addCallback ? 'table-hover' : '') + ' table-sortable table-carded'}>
									<thead>
										<tr>
											<th style={{visibility: addCallback ? 'hidden' : ''}}>Categoría</th>
											<th style={{visibility: addCallback ? 'hidden' : ''}}>Saldo propio</th>
											<th style={{visibility: addCallback ? 'hidden' : ''}}>Saldo por jerarquía</th>
											<th style={{visibility: addCallback ? 'hidden' : ''}}>Saldo total</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ categories?.length > 0 &&
											categories.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className={el.level === 1 ? 'highlight' : ''}>
															<td style={{paddingLeft: (el.level * 15) + 'px'}}>
																<b>{el.code}</b> {el.name ?? '-'}
															</td>
															<td>
																<div style={{visibility: addCallback ? 'hidden' : ''}}>
																	{balances[el.id] === undefined && loader}
																	{balances[el.id] !== undefined && formatNumber(balances[el.id].own, 2)}
																</div>
															</td>
															<td>
																<div style={{visibility: addCallback ? 'hidden' : ''}}>
																	{balances[el.id] === undefined && loader}
																	{balances[el.id] !== undefined && formatNumber(balances[el.id].hierachy, 2)}
																</div>
															</td>
															<td>
																<div style={{visibility: addCallback ? 'hidden' : ''}}>
																	{balances[el.id] === undefined && loader}
																	{balances[el.id] !== undefined && formatNumber(balances[el.id].total, 2)}
																</div>
															</td>
															<td>
																<div className="w-100 d-flex justify-content-between">
																	{!addCallback &&																	
																		<NavLink className="btn-table" to={'/trade-categories/' + type + '/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
																	}
																	{ !el.is_used &&
																		<NavLink className="btn-table text-secondary" to={'/trade-categories/' + type + '/add?parent_id=' + el.id + (popup ? '&popup=true' : '') + (addCallback ? '&add_callback=true' : '')}><i className="bi bi-plus-circle"></i></NavLink>
																	}
																</div>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ categories && !categories.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ categories === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
									<tfoot style={{visibility: addCallback ? 'hidden' : ''}}>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td className="text-end sbold">
												Total:&nbsp;
												{ (balances && Object.keys(balances).length > 0) ?
													<>{formatNumber(jsonToArray(balances).reduce((carry, el) => carry += el.value.own, 0), 2)}</>
													:
													'0'
												}
											</td>
											<td></td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}


