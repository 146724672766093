import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { authUserPermission } from 'helpers/business';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import CategoriesPanel from './CategoriesPanel';
import { default as PrevisionsService } from 'services/Previsions';
import {default as TradeCategoriesService} from 'services/TradeCategories';

const StyledPrevisionsForm = styled.div`
	
	#global-scroll {
		padding: 1px;
		overflow-x: scroll;
		margin-bottom: 10px;
	}
`;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function PrevisionsForm() {
	const actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();
	const popup = queryParams.get('popup') === 'true';

	let [forceReload, setForceReload] = useState(null);
	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);
	let [incomeCategories, setIncomeCategories] = useState([]);
	let [incomeCategoriesData, setIncomeCategoriesData] = useState(null);
	let [expenseCategories, setExpenseCategories] = useState([]);
	let [expenseCategoriesData, setExpenseCategoriesData] = useState(null);
	let [categoriesErrors, setCategoriesErrors] = useState(null);
	
	// eslint-disable-next-line no-unused-vars
	let [months, setMonths] = useState([...Array.from({length: 12}, (_, i) => i + 1)]); 

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				let prevision = await PrevisionsService.get(params.id);
				if (prevision) {
					setData(prevision);
					// setCanEdit(false);
				} else {
					setData((prev) => ({...prev, id: null}));
				}

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		let getCategories = async () => {
			// Incomes
			let incomeCategories = await TradeCategoriesService.listOrdered('incomes', {
				childrens_count: true
			});
			setIncomeCategories([...incomeCategories]);

			// Expenses
			let expenseCategories = await TradeCategoriesService.listOrdered('expenses', {
				childrens_count: true
			});
			setExpenseCategories([...expenseCategories]);
		}	
		getCategories();
	}, []);

	useEffect(() => {
		// Sync scrolls
		document.querySelectorAll('[data-synced-scroll=true]').forEach(element => {
			// Set width to global scroll
			if ( element.id !== 'global-scroll' ) {
				document.querySelector('#global-scroll .inner').style.width = (element.scrollWidth + 20) + 'px';
			}
			
			// Listen scroll
			element.addEventListener('scroll', (e) => {
				document.querySelectorAll('.table-wrapper:not(#' + element.id + ')').forEach(element2 => {
					if ( element2.id !== element.id ) {	
						element2.scrollTop = element.scrollTop;
						element2.scrollLeft = element.scrollLeft;
					}
				});
			});
		});
	}, [data]);

	// FIX: needed to prevent rerendering because categories_data, it causes a lot of rerender on all categories panel even if the panel wasn't updated
	useEffect(() => {
		const incomeCategoriesData = data?.categories_data?.filter(el => el && el.type === 'incomes');
		setIncomeCategoriesData((prev) => {
			if ( JSON.stringify(prev) === JSON.stringify(incomeCategoriesData) ) return prev;
			return incomeCategoriesData;
		});

		const expenseCategoriesData = data?.categories_data?.filter(el => el && el.type === 'expenses');
		setExpenseCategoriesData((prev) => {
			if ( JSON.stringify(prev) === JSON.stringify(expenseCategoriesData) ) return prev;
			return expenseCategoriesData;
		});
	}, [data]);

	const saveData = async (goBack = true) => {	
		setErrors({});
		setCategoriesErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await PrevisionsService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({...result.prevision});

			if ( popup && goBack ) {
				if ( parentWindowProxyCallback ) data = result.prevision; // Fix because parent needs id and update state function on previous line from this line update state async
				window.close();
				return;
			}

			if ( goBack ) navigate('/previsions');
			else {
				setForceReload(Math.random());
		    	navigate('/previsions/edit/' + result.prevision.id + (popup ? '?popup=true' : ''));
			}
		} else {
			toast.error('Ha ocurrido un error al guardar');

			// Set global errors
			let errors = result.errors;
			setErrors(errors);

			// Filter categories errors
			let filteredErrors = {};
			let keys = Object.keys(errors ?? {}).filter(k => k.indexOf('categories_data.') !== -1);
			for(let k of keys) {
				let idx = k.replace('categories_data.', '');
				idx = idx.substring(0, idx.indexOf('.'));
				
				let catData = data.categories_data[idx];
				
				let errorKeyShort = k.replace('categories_data.' + idx + '.', '');

				if ( !filteredErrors[catData.category_id] ) filteredErrors[catData.category_id] = {};
				filteredErrors[catData.category_id][errorKeyShort] = errors[k][0];
			}
			setCategoriesErrors(filteredErrors);
		}
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const setCategoryData = useCallback(catData => {
		setData((prev) => {
			let newData = {...prev};

			if ( !newData.categories_data ) newData.categories_data = [];

			let existingIdx = newData?.categories_data?.findIndex(el => el.category_id === catData.category_id);
			if ( existingIdx !== -1 ) {
				newData.categories_data[existingIdx] = catData;
			} else {
				newData.categories_data.push(catData);
			}

			return newData;
		});
	}, []);

	const deletePrevision = async () => {
		if ( !window.confirm('¿Estás seguro de eliminar esta previsión?') ) return;

		setLoading(true);

		let result = await PrevisionsService.delete(data.id);

		setLoading(false);

		if ( result ) {
			toast.success('Previsión eliminada');
			navigate('/previsions');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}
	
	const isValidYear = data?.year && !isNaN(parseInt(data?.year));

	// const canSave = data?.families?.length > 0;
	const canSave = true;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<StyledPrevisionsForm>
				<div className="page-title">
					<h1>Ficha de previsión</h1>
					<button onClick={() => popup ? window.close() : navigate('/previsions')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className={'row ' + (popup ? 'justify-content-center mt-3' : '')}>
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-12">
											<div className="row">
												<div className="col-md-3 mb-3">
													<CustomInput label="Nombre" type="text" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} readOnly={!canEdit} />
													{ errors.name &&
														<div className="invalid-feedback d-block">{ errors.name[0] }</div>
													}
													{ errors.categories_data &&
														<div className="invalid-feedback d-block">{ errors.categories_data[0] }</div>
													}
												</div>
												<div className="col-md-1 mb-3">
													<CustomInput label="Año" type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('year', e.target.value)} value={data.year ?? ''} readOnly={!canEdit} />
													{ errors.year &&
														<div className="invalid-feedback d-block">{ errors.year[0] }</div>
													}
												</div>
											</div>
										</div>

										<div className="col-md-12">
											<CategoriesPanel
												type="incomes"
												previsionId={data.id ?? null}
												data={incomeCategoriesData}
												categories={incomeCategories}
												setCategoryData={setCategoryData}
												canEdit={canEdit && isValidYear}
												months={months}
												errors={categoriesErrors}
												forceReload={forceReload}
											/>
										</div>

										<div className="col-md-12">
											<CategoriesPanel
												type="expenses"
												previsionId={data.id ?? null}
												data={expenseCategoriesData}
												categories={expenseCategories}
												setCategoryData={setCategoryData}
												canEdit={canEdit && isValidYear}
												months={months}
												errors={categoriesErrors}
												forceReload={forceReload}
											/>
										</div>

										<div className="col-md-12">
											<CategoriesPanel
												type="balance"
												previsionId={data.id ?? null}
												data={data?.categories_data ?? []}
												categories={[...expenseCategories, ...incomeCategories]}
												setCategoryData={setCategoryData}
												canEdit={false}
												months={months}
												errors={categoriesErrors}
												forceReload={forceReload}
											/>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div id="global-scroll" data-synced-scroll="true"><div className="inner"></div></div>

									<div className="row">
										<div className="col-4">
											{ ( data.id && authUserPermission('delete') ) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deletePrevision()}>Eliminar</button>							
											}
										</div>
										<div className="col-8 text-end">
											{ ((data.id && authUserPermission('edit')) || authUserPermission('add') ) &&
												<>
													<div className="dropdown d-inline">
														<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
															Acciones
														</button>
														<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
															<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
															<li><hr className="dropdown-divider" /></li>

															<li><button className="dropdown-item" onClick={() => saveData(false)} disabled={!canSave}>Guardar</button></li>						
															<li><button className="dropdown-item" onClick={() => saveData()} disabled={!canSave}>Guardar y salir</button></li>
														</ActionsContextMenu>
													</div>

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)} disabled={!canSave}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()} disabled={!canSave}>Guardar y salir</button>					
												</>
											}		
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</StyledPrevisionsForm>
		</Layout>
	);
}