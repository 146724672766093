import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { authUserPermission } from 'helpers/business';
import { formatNumber, loader } from 'helpers/generic';
import BusinessChecker from 'components/BusinessChecker';
import moment from 'moment';
import BankAggregatorLogModal from './BankAggregatorLogModal';
import { default as TreasuriesService } from "services/Treasuries";
import { default as BankReconciliationService } from "services/BankReconciliation";

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			/* width: 150px; */
		}

		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4) {
			width: 120px;
			text-align: right;
		}

		&:nth-child(5) {
			width: 20px;
		}
	}
`;

const Dragging = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 25px;
	z-index: 99;

	button {
		font-size: 20px;
		color: red;
	}
`;

const Uploading = styled.div`
	background: white;
	border: 1px solid var(--bs-primary);
	border-radius: 5px;
	margin-top: 20px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 10px;
`;

let searchTimeout = null;

export default function Treasury() {
	const [queryParams] = useSearchParams();

	const fileRef = useRef(null);

	let [file, setFile] = useState(null);
	let [fileErrors, setFileErrors] = useState([]);
	let [uploading, setUploading] = useState(false);
	let [dragging, setDragging] = useState(null);
	let [treasuries, setTreasuries] = useState({});
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setTreasuries({...treasuries, data: undefined});
		_setPage(page);
	}
	let [bankAggregatorLogModalOpened, setBankAggregatorLogModalOpened] = useState(null);


	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		const getTreasuries = async () => {
			let treasuries = await TreasuriesService.list({
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				type: 'bankaccount',
				with_pending_noreconciliatedbankmovements_count: true
			});

			setTreasuries({...treasuries});
			setSkeletonRows(treasuries?.data?.length);
		}

		getTreasuries();
	}, [page, search, sortField, sortDirection]);

	const onDropHandler = (e) => {
		e.preventDefault();

		setDragging(false);

		let file = e.dataTransfer.files[0];
		setFile(file);
	}

	const onDragHover = (e) => {
		e.preventDefault();
		setDragging(true);
	}

	const openFileInput = () => {
		setFile(null);
		fileRef.current.value = null;
		fileRef.current.click();
	}

	const fileInputChange = (e) => {
		let file = e.target.files[0];
		setFile(file);
	}

	useEffect(() => {
		if ( !file ) return;
		setFileErrors([]);

		const uploadFile = async () => {
			setUploading(true);

			let result = await BankReconciliationService.uploadFile(file);
			if ( result.status === 0 ) setFileErrors([...result.errors]);
			if ( result.status === 1 ) toast.success('Fichero subido');
			
			setFile(null);
			setUploading(false);
		}
		uploadFile();
	}, [file])

	return (
		<LoggedLayout>
			<BusinessChecker />
			<section
				onDrop={onDropHandler}
				onDragOver={(e) => onDragHover(e)}
				// onDragLeave={(e) => setDragging(false)}
			>
				{ dragging && 
					<Dragging>
						Suelta el archivo aquí
						<div>
							<button className="btn btn-link btn-sm text-danger" onClick={() => setDragging(false)}>Cancelar</button>
						</div>
					</Dragging> }
				<div className="page-title">
					<h1>Conciliación bancaria</h1>
					
					{ authUserPermission('add') &&
						<button className="btn btn-sm btn-light ms-auto" onClick={openFileInput}>Subir fichero norma 43</button>
					}
				</div>

				{ (uploading || fileErrors.length > 0) &&
					<Uploading>
						{ uploading && loader }
						{ fileErrors.length > 0 && 
							fileErrors.map((el, idx) => {
								return <div key={idx} className="text-danger">- {el}</div>
							})
						}
					</Uploading>
				}

				<div className="page-content">
					<input type="file" ref={fileRef} className="d-none" onChange={fileInputChange}  />


					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'balance'} onClick={() => sortTableClick('balance')}>Saldo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'synced_at'} onClick={() => sortTableClick('synced_at')}>Últ. sincronización</ThSortable>
											<th>Sin conciliar</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ treasuries.data?.length > 0 &&
											treasuries.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>
																{el.name ?? '-'}
																<div><small>{el.data?.iban}</small></div>
															</td>
															<td>{el.balance ? formatNumber(el.balance) : 0}</td>
															<td>
																{el.synced_at ? moment(el.synced_at).format('DD-MM-YYYY HH:mm') : ''}
																&nbsp;
																<button className="btn btn-link btn-sm p-0" onClick={() => setBankAggregatorLogModalOpened(el)}><i className="bi bi-eye"></i></button>	
															</td>
															<td>{el.noreconciliatedbankmovements_count}</td>
															<td>
																<NavLink className="btn-sm text-decoration-none border border-primary border-1 bg-white d-block" to={'/bank-reconciliation/' + el.id + '/movements'}>
																	<i className="bi bi-arrow-left-right"></i>
																</NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ treasuries.data && !treasuries.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ treasuries.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={treasuries?.current_page}
									max={treasuries?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			{bankAggregatorLogModalOpened &&
				<BankAggregatorLogModal 
					treasury={bankAggregatorLogModalOpened}
					closeCallback={() => {
						setBankAggregatorLogModalOpened(false);
					}}
				/>
			}
		</LoggedLayout>
	);
}


