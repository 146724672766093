import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { getBusiness, authUserPermission } from 'helpers/business';
import { formatNumber } from 'helpers/generic';
import { default as TreasuriesService } from 'services/Treasuries';
import BusinessChecker from 'components/BusinessChecker';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 150px;
		}

		&:nth-child(3),
		&:nth-child(4) {
			text-align: right;
			width: 120px;
		}

		&:nth-child(5) {
			width: 65px;
		}

	}
`;

let searchTimeout = null;

export default function Treasury() {
	const [queryParams] = useSearchParams();

	let [treasuries, setTreasuries] = useState({});
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setTreasuries({...treasuries, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		const getTreasuries = async () => {
			let treasuries = await TreasuriesService.list({
				business_id: getBusiness().id,
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				with_balance: true
			});

			setTreasuries({...treasuries});
			setSkeletonRows(treasuries?.data?.length);
		}

		getTreasuries();
	}, [page, search, sortField, sortDirection]);

	return (
		<LoggedLayout>
			<BusinessChecker />
			<section>
				<div className="page-title">
					<h1>Tesorería</h1>
					
					{ authUserPermission('add') &&
						<NavLink to="/treasury/add" className="btn btn-sm btn-light ms-auto">Nueva tesorería</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'type'} onClick={() => sortTableClick('type')}>Tipo</ThSortable>
											{/* <ThSortable direction={sortDirection} active={sortField === 'contableaccount.balance'} onClick={() => sortTableClick('contableaccount.balance')}>Saldo</ThSortable> */}
											{/* <ThSortable direction={sortDirection} active={sortField === 'real_balance'} onClick={() => sortTableClick('real_balance')}>Saldo real</ThSortable> */}
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ treasuries.data?.length > 0 &&
											treasuries.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>
																{el.name ?? '-'}
																{ el.default === 1 && 
																	<div><small><i className="bi bi-asterisk text-primary"></i> Por defecto</small></div>
																}
															</td>
															<td>{el.type === 'bankaccount' ? 'Cuenta bancaria' : 'Caja / efectivo'}</td>
															{/* <td>{el.contableaccount ? formatNumber(el.contableaccount.balance) : 0}</td> */}
															{/* <td>{el.real_balance ? formatNumber(el.real_balance) : 0}</td> */}
															<td className="text-end">
																<NavLink className="btn-table" to={'/treasury/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ !treasuries.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ treasuries.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={treasuries?.current_page}
									max={treasuries?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


