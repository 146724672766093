import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import CustomFieldsPanel from 'components/CustomFieldsPanel';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import useStore from "store";
import { authUserPermission } from 'helpers/business';
import PermissionsTable from './PermissionsTable';
import Notes from 'components/Notes';
import DocumentsPanel from 'components/DocumentsPanel';
import { readableSize } from 'helpers/generic';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import { default as BusinessesService } from 'services/Businesses';

const ImageWrapper = styled.div`
	width: 100%;
	text-align: center;

	.image-square {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: var(--bs-gray-200);	
		width: 100%;
		max-width: 100px;
		height: 100px;
		cursor: pointer;
		border-radius: 3px;

		img {
			display: block;
			width: 100%;
		}

		span {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			font-size: 10px;
		}

		i {
			position: absolute;
			top: 5px;
			right: 5px;
			display: inline-flex;
			padding: 2px 5px;
			color: white;
			background: var(--bs-danger);
			border-radius: 3px;
			font-size: 11px;

			&:hover {
				opacity: 0.9;
				transform: translateY(-1px);
			}
		}

		input[type=file] {
			width: 0;
			height: 0;
			opacity: 0;
		}
	}
`;

export default function BusinessesForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	const logoFileInput = useRef(null);

	const config = useStore(state => state.config);

	let [data, setData] = useState({
		
		// permissions: {},
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [logo, setLogo] = useState(null);
	let [canEdit, setCanEdit] = useState(true);
	
	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				let business = await BusinessesService.get(params.id);
				if ( business ) {
					setData({...business});
					setLogo(business.logo_base64_url);
				}

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);


	const deleteBusiness = async () => {
		const c = window.confirm('¿Quieres eliminar esta empresa?');
		if ( !c ) return;

		let result = await BusinessesService.delete(data.id);
		if ( result ) {
			toast.success('Empresa borrada')
	    	navigate(-1);
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		let result = null;
		try {
			result = await BusinessesService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({...result.business});
		    setLogo(result.business.logo_base64_url);
			
			if ( goBack ) {
				if ( popup ) window.close();
				else navigate(-1);
			} else {
				if ( !params.id ) navigate('/businesses/edit/' + result.business.id + '?edit=true' + (popup ? '&popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setPermissions = (permissions) => {
		setData({...data, permissions: permissions});
	}

	const openLogoFileInput = () => {
		logoFileInput.current.value = null;
		logoFileInput.current.click();
	}

	const loadLogo = async (file) => {
		// Reset
		setDataField('logo_base64_url', null);
		setLogo(null);

		// Check if exists the file
        if ( !file ) return false;

        // Check extension
        var allowed = 'png jpg jpeg gif bmp';
        var extension = file.type.replace('image/', '');
        if ( !extension.length || allowed.indexOf(extension) === -1 ) {
            toast.error('Extensión no válida. Solo se permiten ficheros: png, jpg, gif y bmp');
            return false;
        }

        // Check size
        if ( file.size > config.upload_max_filesize ) {
            toast.error('El tamaño del fichero es superior al permitido ('+readableSize(config.upload_max_filesize)+')');
            return false;
        }

        // Read image and show
        let base64image = '';
        await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(base64image = reader.result);
			reader.readAsDataURL(file);
		});
		setLogo(base64image);

        // Set data
        setDataField('logo_base64_url', base64image);
	}

	const removeLogo = (e) => {
		e.stopPropagation();
		setDataField('logo_base64_url', 'remove');
		setLogo(null);
	}

	const openLogo = async (e) => {
		let base64src = e.currentTarget.src;
		let blob = await fetch(base64src).then(res => res.blob());
		const blobUrl = URL.createObjectURL(blob);
		openPopupWindow(blobUrl);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de empresa</h1>
					<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-7">
											<LabeledFrame className="mb-3" label="Datos básicos">
												<div className="row">
													<div className="col-md-10">
														<div className="row">
															<div className="col col-static-200">
																<CustomInput 
																	label="Nombre" 
																	type="text" 
																	className="form-control form-control-sm" 
																	onChange={(e) => setDataField('name', e.target.value)} 
																	value={data.name ?? ''} 
																	readOnly={!canEdit} 
																/>
																{ errors.name &&
																	<div className="invalid-feedback d-block">{ errors.name[0] }</div>
																}
															</div>
															<div className="col col-static-250">
																<div className="mb-2">
																	<CustomInput 
																		label="Razón social" 
																		type="text" 
																		className="form-control form-control-sm" 
																		onChange={(e) => setDataField('business_name', e.target.value)} 
																		value={data.business_name ?? ''} 
																		readOnly={!canEdit}
																	/>
																	{ errors.business_name &&
																		<div className="invalid-feedback d-block">{ errors.business_name[0] }</div>
																	}
																</div>
															</div>
															<div className="col col-static-120">
																<div className="mb-2">
																	<CustomInput 
																		label="CIF" 
																		type="text" 
																		className="form-control form-control-sm" 
																		onChange={(e) => setDataField('vatnumber', e.target.value)} 
																		value={data.vatnumber ?? ''} 
																		readOnly={!canEdit}
																	/>
																	{ errors.vatnumber &&
																		<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
																	}
																</div>
															</div>
														</div>
													</div>
													<div className="col col-static-110">
														<ImageWrapper>
															{ errors.logo_base64_url &&
																<div className="invalid-feedback d-block">{ errors.logo_base64_url[0] }</div>
															}
															<div className="image-square" onClick={() => !logo && canEdit ? openLogoFileInput() : ''}>
																<input type="file" ref={logoFileInput} onChange={(e) => loadLogo(e.target.files[0])} />

																{ logo ? 
																	<React.Fragment>
																		<img src={logo} alt="logo" onClick={openLogo} />
																		{ canEdit && 
																			<i onClick={(e) => removeLogo(e)}>Eliminar logo</i>
																		}
																	</React.Fragment> 
																	: 
																	
																	<span className="text-center">Seleccionar</span> 
																}
															</div>
														</ImageWrapper>
													</div>	
												</div>												
											</LabeledFrame>

											<LabeledFrame className="mb-3" label="Dirección">
												<div className="row">
													<div className="col col-static-300">
														<div className="mb-2">
															<CustomInput 
																label="Dirección" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('address', e.target.value)} 
																value={data.address ?? ''}
																readOnly={!canEdit}
															/>
															{ errors.address &&
																<div className="invalid-feedback d-block">{ errors.address[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="C.P." 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('postalcode', e.target.value)} 
																value={data.postalcode ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.postalcode &&
																<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput 
																label="Ciudad" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('city', e.target.value)} 
																value={data.city ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.city &&
																<div className="invalid-feedback d-block">{ errors.city[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-180">
														<div className="mb-2">
															<CustomInput 
																label="Provincia" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('province', e.target.value)} 
																value={data.province ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.province &&
																<div className="invalid-feedback d-block">{ errors.province[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="País" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('country', e.target.value)} 
																value={data.country ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.country &&
																<div className="invalid-feedback d-block">{ errors.country[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-320">
														<div className="mb-2">
															<CustomInput 
																label="Registro mercantil" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('commercial_registry', e.target.value)} 
																value={data.commercial_registry ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.commercial_registry &&
																<div className="invalid-feedback d-block">{ errors.commercial_registry[0] }</div>
															}
														</div>
													</div>
												</div>
											</LabeledFrame>
										</div>
										<div className="col-lg-5">
											<CustomFieldsPanel 
												className="mb-3"
												type="businesses"
												fields={data.customfields_array}
												setFields={(fields) => setData((data) => ({...data, customfields_array: fields}))}
												readOnly={!canEdit}
											/>

											<LabeledFrame className="mb-3" label="Usuarios y permisos">
												<PermissionsTable readOnly={!canEdit} permissions={data.permissions} setPermissions={(data) => setPermissions(data)} />			
											</LabeledFrame>	

											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea readOnly={!canEdit} className="form-control form-control-sm" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''}></textarea>
											</LabeledFrame>

											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
												readOnly={!canEdit}
											/>

											{ data.id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													community={{id: data.id, name: data.name}}
												/>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete') ) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteBusiness()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ authUserPermission('edit') &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
																<li><hr className="dropdown-divider" /></li>
																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>	
												</React.Fragment>
											}						
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}