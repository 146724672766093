import React, { useState, useRef, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const PaginatorStyled = styled.div`
	.input {
		cursor: default;
		color: var(--bs-gray);
		white-space: nowrap;
		text-align: center;

		.fakeInput {
			display: inline-block;
			outline: none;
			color: #333;
			cursor: text;
			min-width: 10px;
		}
	}

	button {
		width: 40px;

		&:focus {
			box-shadow: none !important;
		}
	}
`;

export default function Paginator(props) {
	const [queryParams, setQueryParams] = useSearchParams();

	const [ min, setMin ] = useState(0);
	const [ max, setMax ] = useState(0);
	const [ current, _setCurrent ] = useState(0);
	const lastCurrent = useRef(current);
	const setCurrent = (current) => {
		lastCurrent.current = current;
		_setCurrent(current);
	}

	// Ref
	const inputRef = useRef(null);

	useEffect(() => {
  		setMin(props.min ?? 1);
  		setMax(props.max ?? 1);

  		// Set query params. This needed to be executed before setCurrent to avoid problems
  		if ( props.current && props.current !== lastCurrent.current ) {
  			let params = {...queryParams};
  			if ( props.current === 1 ) {
  				delete params.page;
  			} else {
  				params.page = props.current;
  			}
			setQueryParams(params);
  		}

  		setCurrent(props.current ?? 1);
  	}, [props, queryParams, setQueryParams]);

	function setPage(page) {
		page = parseInt(page);
		if ( page > max ) page = max;
		if ( page < min ) page = min;
		if ( isNaN(page) ) page = '';
		if ( page && page !== current ) props.changeCallback(page);
		inputRef.current.innerHTML = page;
	}

	function addPage() {
		setPage(current+1);
	}

	function subPage() {
		setPage(current-1);
	}

	return (
		<PaginatorStyled id="paginator">
			<div className="input-group">
				<button className="btn btn-sm btn-secondary text-white" onClick={(e) => subPage()}><i className="bi bi-dash"></i></button>
				<div className="input form-control form-control-sm">
					<div 
						ref={inputRef} 
						className="fakeInput" 
						onBlur={(e) => setPage(e.target.innerHTML)}
						contentEditable 
						suppressContentEditableWarning={true}
					>{current}</div> / {max}
				</div>
				<button className="btn btn-sm btn-secondary text-white" onClick={(e) => addPage()}><i className="bi bi-plus"></i></button>
			</div>
		</PaginatorStyled>
	);
}