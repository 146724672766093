import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "store";

export default function ClearHistory() {
	const navigate = useNavigate();
	const dispatch = useStore(state => state.dispatch);
	const clearHistory = useStore(state => state.clearHistory);    
	
	useEffect(() => {
		if ( !clearHistory ) return;

		// Disable clear history
		dispatch({
			type: 'clearHistory',
			data: false
		});

		// Navigate replacing
		navigate('/', {replace: true});

	}, [clearHistory, dispatch]);

	return (<></>);
}