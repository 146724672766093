import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import CustomInput from 'components/CustomInput';
import { default as SectorService } from 'services/Sectors';

let parentWindowProxyCallbackSector = window.opener?.PopupProxyCallbackSector;

export default function SectorsForm(props) {
	const navigate = useNavigate();
	const params = useParams();

	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		order: 0,
		name: '',
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallbackSector(data);
		};
	}, [data]);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				let data = await SectorService.get(params.id);
				if ( !data ) {
					toast.error('Sector no encontrado');
					navigate('/sectors');
				}
				setData(data);

				setLoading(false);
			}
			getData();
		} else {
			const getLastOrder = async () => {
				let result = await SectorService.getLastOrder();
				setData(data => ({...data, order: result.order}));
			}
			getLastOrder();
		}
	}, [params.id]);

	const deleteSector = async () => {
		const c = window.confirm('¿Quieres eliminar este sector?');
		if ( !c ) return;

		let result = await SectorService.delete(data.id);

		if ( result ) {
			toast.info('Sector borrado')
			navigate('/sectors');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});

		let result = await SectorService.save(data);
		if ( result?.status ) {
			toast.success('Datos guardados');
			setData({...result.sector});

			if ( goBack ) {
				if ( popup ) {
					data = result.sector; // Fix to send last data on before unload
					window.close();
				}
				else navigate('/sectors');
			} else if ( !data.id ) {
		    	navigate('/sectors/edit/' + result.sector.id + '?edit=true' + (popup ? '&popup=true' : ''));
			}
		} else {
			if ( result?.errors ) setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de sector</h1>
					<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="row">
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
													{ errors.code &&
														<div className="invalid-feedback d-block">{ errors.code[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
													{ errors.name &&
														<div className="invalid-feedback d-block">{ errors.name[0] }</div>
													}
												</div>
											</div>
											<div className="col-lg-4">
												<div className="mb-2">
													<CustomInput label="Orden" type="number" className="form-control form-control-sm" onChange={(e) => setDataField('order', e.target.value)} value={data.order ?? ''} />
													{ errors.order &&
														<div className="invalid-feedback d-block">{ errors.order[0] }</div>
													}
												</div>
											</div>
										</div>	
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteSector()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}