import React, { useEffect } from 'react';
import styled from 'styled-components';
import EmpoweredSelector from 'components/EmpoweredSelector';
import TreeRow from './TreeRow';
import { default as BusinessesService } from 'services/Businesses';

const TreeStyled = styled.div`
	width: 100%;
	height: 100%;
	padding: 10px;
	overflow-y: auto;

	background: var(--bs-light);

	.empowered-selector {
		margin-bottom: 10px;

		.empowered-selector_label {
			background: white;
		}
	}
`;


export default function Tree(props) {
	let business = props.business;
	let setBusiness = props.setBusiness;
	let reload = props.reload;

	const loadBusinesses = async (input, callback) => {
		let result = await BusinessesService.list({
			search: input,
			no_paginate: true,
		});
		
		let formatted = result?.map((el) => {
			return {
				value: el, 
				label: (
					<div className="d-flex align-items-center">
						<span className="badge bg-secondary text-white me-1">{el.name_short}</span>
						{el.name}
					</div>
				)
			};
		});

		callback(formatted);
	}

	const openFolder = (el) => {
		props.openFolder(el);

		// Dropdown if closed
		// if ( el && openedIds.indexOf(el.id) === -1 ) dropdownClick(null, el.id, true);
	}

	return (
		<TreeStyled onDrop={(e) => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
			<EmpoweredSelector
				tabIndex="-1"
				load={(input, callback) => loadBusinesses(input, callback)}
				onChange={(value) => setBusiness(value)}
				timeout={250}
				label={
					(business && business.id) ? 
						<div className="business_selected_label">
							{/* <span className="name_short badge bg-secondary text-light me-1">{business.name}</span> */}
							<span className="name">{business.name}</span>
						</div>
					: 
						null
				}
				placeholder="Seleccionar empresa"
				value={business?.id ?? null}
				preventClickPropagation={true}
			/>

			<TreeRow 
				folder={
					business?.id ?
					{
						id: business.folder_id,
						name: business.name,
						code: 'business',
						has_content: true
					}
					:
					{
						id: 			-1,
						name: 			'Documentos',
						code: 			'documents',
						has_content: 	true
					}
				}
				openFolder={openFolder}
				selectedFolderId={props.folder?.id}
				business={business}
				reload={reload}
				level={0}
			/>
		</TreeStyled>
	);
}

