import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import FolderModal from './FolderModal';
import FileModal from './FileModal';
import CopyMoveModal from './CopyMoveModal';
import { readableSize, loader, openPopupWindow, CristalLoader, downloadFile } from 'helpers/generic';
import { getRouterBasename } from 'helpers/config';
import { authUserPermission } from 'helpers/business';
import { default as FilesService } from 'services/Files';
import ThSortable from 'components/ThSortable';

const ExplorerStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	// position: relative;
`;

const FileHidden = styled.input`
	display: none;
`;

const BreadCrumbs = styled.div`
	margin-top: 5px;
	padding: 5px 10px;
	font-size: 12px;
	border-bottom: 2px solid var(--bs-gray-200);

	.clickable {
		span.name {
			color: var(--bs-primary);
			cursor: pointer;
		}
	}
`;

const ActionsBar = styled.div`
	padding: 10px;

	#add-dropdown {

		button.btn-primary {
			
			i {
				display: inline-block;
				transform: scale(1.4);
			}
		}
	}
`;

const TableScrollable = styled.div`
	overflow: ${props => props.isPanel ? '' : 'auto'};
	height: 100%;
`;

const Table = styled.table`
	width: 100%;

	thead {
		border-bottom: 1px solid var(--bs-gray-200);

		th {
			padding: 5px;
			font-weight: 500;

			&:first-of-type,
			&:last-of-type {
				padding-left: 10px;
			}

			&:nth-child(2) {
				width: 120px;
			}

			&:nth-child(3) {
				width: 140px;
			}

			&:nth-child(4) {
				width: 150px;
			}
		}
	}

	tbody {
		border-bottom-width: 0;

		tr {
			user-select: none;

			&:hover {
				background: var(--bs-gray-100);
			}

			td {
				padding: 5px;
				border-bottom: 1px solid var(--bs-gray-200);

				&:first-of-type,
				&:last-of-type {
					padding-left: 10px;
					white-space: nowrap;
				}

				&:last-of-type {
					width: 20px;

					.btn-light {
						background: var(--bs-gray-500);
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					font-size: 13px;
					line-height: 13px;
					white-space: nowrap;
				}

				&.folder_name {
					color: var(--bs-primary);

					i {
						&.bi-folder-fill {
							color: var(--bs-orange);
						}

						&.bi-arrow-90deg-up {
							color: var(--bs-green);
						}
					}

					span {
						display: inline-block;
						margin-left: 5px;
					}

					&:hover {
						cursor: pointer;

						span {
							text-decoration: underline;
						}
					}
				}

				&.file_name {
					color: var(--bs-primary);

					i {
						&.bi-file-fill {
							color: var(--bs-primary);
						}
					}

					a {
						display: inline-block;
						margin-left: 5px;
						text-decoration: none;

					}
				}

				.responsive_details {
					font-size: 13px;
					line-height: 13px;
					margin-left: 18px;
					margin-top: 2px;
					color: var(--bs-secondary);
				}
			}
		}
	}


	/* // Responsive & popup */
	@media (max-width: 768px) {
		th,td {
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				display: none;
			}
		}
	}

	@media (max-width: 450px) {
		&:nth-child(1) {
			div.d-flex {
				max-width: 250px;
				overflow: hidden;	

				a {
					display: block;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	&.isPanel {
		th,td {
			&:nth-child(2),
			&:nth-child(4) {
				/* display: none; */
			}

			&:nth-child(3) {
				width: 100px;
			}
		}

		&:nth-child(1) {
			div.d-flex {
				max-width: 250px;
				overflow: hidden;	

				a {
					display: block;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
`;

const FilePickerCristalWaiting = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 999999;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 20px;
`;

let parentWindowFilePickerProxyCallback = window.opener?.FilePickerProxyCallback;

export default function Explorer(props) {
	const [editFolderModal, setEditFolderModal] = useState(null);
	const [editFileModal, setEditFileModal] = useState(null);

	const [copyMoveFileModalAction, setCopyMoveFileModalAction] = useState(null);
	const [copyMoveFileModal, _setCopyMoveFileModal] = useState(null);
	const setCopyMoveFileModal = (file, action) => {
		_setCopyMoveFileModal(file);
		setCopyMoveFileModalAction(action);
	}
	const [selectMultiple, _setSelectMultiple] = useState(false);
	const setSelectMultiple = (value) => {
		_setSelectMultiple(value);
		if ( !value ) setSelectedFiles([]);
	}
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [parentFilePickerCristalWaiting, setParentFilePickerCristalWaiting] = useState(false);

	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');

	const fileRef = useRef(null);

	const folder = props.folder;
	const openFolder = props.openFolder;
	const business = props.business;
	const isPanel = props.isPanel;
	const rootFolderId = props.rootFolderId;
	const isFilePicker = props.isFilePicker;


	useEffect(() => {
		if ( !isFilePicker ) return;

		window.onbeforeunload = () => {
			parentWindowFilePickerProxyCallback(null);
		}
	}, [isFilePicker]);

	// Reset search if folder changes
	useEffect(() => {
		setSearch('');
	}, [folder]);

	const deleteFolder = async (folder) => {
		let c = window.confirm('¿Seguro que quieres eliminar esta carpeta?');
		if ( !c ) return false;

		let result = await FilesService.deleteFolder(folder.id);
		if ( result ) {
			toast.success('Datos eliminados');
			openFolder(folder.parent); // Reload parent folder to update screen
			props.updateTree(); // Update tree from parent
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const openFileInput = () => {
		fileRef.current.value = null;
		fileRef.current.click();
	}

	const handleFiles = (files) => {
		if ( !files.length ) return;

		let filesToUpload = [];
		for (let i=0; i<files.length; i++) {
			let file = files.item(i);
			filesToUpload.push(file);
		}

		props.openUploadModal(filesToUpload);
	}

	const deleteFile = (file) => {
		let c = window.confirm('¿Seguro que quieres eliminar este documento?');
		if ( !c ) return false;

		let result = FilesService.deleteFile(file.id);
		if ( result ) {
			toast.success('Documento eliminado');
			openFolder(folder); // Reload folder to update screen
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const downloadFileFix = async (file) => {
		setLoading(true);
		await downloadFile('/api/documents/download-file/' + file.id + '?business_id=' + (business?.id ?? ''));
		setLoading(false);
	}

	const downloadFolderFix = async (folder) => {
		setLoading(true);
		await downloadFile('/api/documents/download-folder/' + folder.id + '?business_id=' + (business?.id ?? ''));
		setLoading(false);
	}

	const clickFileHandler = (e, file) => {
		if ( !isFilePicker ) openFile(e, file);
		else {
			parentWindowFilePickerProxyCallback(file);
			window.close();
		}
	}

	const openFile = (e, file) => {
		e.preventDefault();
		
		const baseUrl = e.currentTarget.href;
		if ( file.viewable ) return openPopupWindow(baseUrl + file.id);

		return downloadFileFix(file);
	} 

	const dragOverHandlerParentFix = (e) => {
		// Check if contain files to prevent propagate to parent "Documents"
		if ( !e.dataTransfer.types.includes('Files') ) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	const dropHandlerParentFix = (e) => {
		e.preventDefault();
		
		// If not contains files, stop propagation to parent "Documents" where the file drop events is handled
		if ( !e.dataTransfer.types.includes('Files') ) e.stopPropagation();
	}

	const dragStartHandler = (e, file) => {
		e.dataTransfer.effectAllowed = 'move';
		e.dataTransfer.setData('fileId', file.id);
	}

	const dragOverFolderHandler = (e) => {
		// Check if contain files to prevent to parent "Documents"
		if ( !e.dataTransfer.types.includes('Files') ) {
			e.preventDefault();
			e.stopPropagation();
		}

		// Set background
		e.currentTarget.style.background = 'var(--bs-gray-200)';
	}

	const dragLeaveFolderHandler = (e) => {
		e.preventDefault();
		e.stopPropagation();

		// Remove background
		e.currentTarget.style.background = 'inherit';
	}

	const dropFolderHandler = (e) => {
		e.preventDefault();

		// Remove Background
		e.currentTarget.style.background = 'inherit';

		// Get folder id
		let folderId = e.currentTarget.dataset.id;
		
		// If not contains files, stop propagation to parent "Documents" where the file drop events is handled
		if ( !e.dataTransfer.types.includes('Files') ) e.stopPropagation();
		else return; // Not continues because it contains files and they'll be handled by parent "Documents"

		// Get file
    	let fileId = e.dataTransfer.getData('fileId');

		// Move
		let result = FilesService.move(fileId, folderId);
		if ( result ) {
			openFolder(folder); // Reload folder to update screen
		} else {
			toast.error('Ha ocurrido un error al mover el archivo');
		}
	}

	const openSendEmailPopupWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href, 'sendemail');
	}

	const toggleSelectedFile = (el) => {
		let selected = [...selectedFiles];
		let idx = selected.findIndex(fEl => fEl.id === el.id);
		if ( idx === -1 ) selected.push(el);
		else selected.splice(idx, 1);
		setSelectedFiles(selected);
	}

	const copyFromFolderClickHandler = () => {
		window.FilePickerProxyCallback = (file) => {
			if ( file ) {
				copyFileToCurrentFolder(file);
			}

			setParentFilePickerCristalWaiting(false);
			window.FilePickerProxyCallback = null;
		}

		openPopupWindow(getRouterBasename() + '/documents?file_picker=true', 'documents_file_picker');
		setParentFilePickerCristalWaiting(true);
	}

	const copyFileToCurrentFolder = async (file) => {
		let result = await FilesService.copy(file.id, folder.id);
		if ( result ) {
			toast.success('Documento copiado');
			openFolder(folder); // Reload folder to update screen
		} else {
			toast.error('Ha ocurrido un error');
		}
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const sortCompare = (a, b) => {
		if ( sortField === 'name' ) {
			if ( a.name < b.name ) return sortDirection === 'asc' ? -1 : 1;
			if ( a.name > b.name ) return sortDirection === 'asc' ? 1 : -1;
			return 0;
		}
		if ( sortField === 'created_at' ) {
			if ( a.created_at > b.created_at ) return sortDirection === 'asc' ? -1 : 1;
			if ( a.created_at < b.created_at ) return sortDirection === 'asc' ? 1 : -1;
			return 0;
		}
		if ( sortField === 'size' ) {
			if ( a.size < b.size ) return sortDirection === 'asc' ? -1 : 1;
			if ( a.size > b.size ) return sortDirection === 'asc' ? 1 : -1;
			return 0;
		}
		if ( sortField === 'userName' ) {
			if ( a.user?.name ?? 'SISTEMA' < b.user?.name ?? 'SISTEMA' ) return sortDirection === 'asc' ? -1 : 1;
			if ( a.user?.name ?? 'SISTEMA' > b.user?.name ?? 'SISTEMA' ) return sortDirection === 'asc' ? 1 : -1;
			return 0;
		}
	}

	// Sort subfolders and files
	if ( folder.id ) {
		if ( folder.subfolders && folder.subfolders.length > 0 ) {
			folder.subfolders.sort((a, b) => {
				return sortCompare(a, b);
			});
		}
		
		if ( folder.files && folder.files.length > 0 ) {
			folder.files.sort((a, b) => {
				return sortCompare(a, b);
			});
		}
	}


	let resultsCount = 0;

	return (
		<React.Fragment>
			{ loading && <CristalLoader /> }
			{ parentFilePickerCristalWaiting && 
				<FilePickerCristalWaiting>  
					Esperando a que selecciones un archivo en la otra ventana...
					{loader}
				</FilePickerCristalWaiting> 
			}
			<ExplorerStyled 
				onDragOver={(e) => dragOverHandlerParentFix(e)} // This handler is needed to prevent event propagation on parent "Documents" where the file drag events is handled
			 	onDrop={(e) => dropHandlerParentFix(e)}
			>
				<ActionsBar>
					<div className="row">
						<div className={'d-flex col-' + (isPanel ? '6' : '6')}>
							<div className="dropdown d-inline-block" id="add-dropdown">
								<button className="btn btn-sm btn-primary text-white" type="button" data-bs-toggle="dropdown">
									<i className="bi bi-plus"></i>
								</button>
								<ul className="dropdown-menu">
									{ folder?.id !== -1 && <li><button className="dropdown-item btn btn-link rounded-0" onClick={() => openFileInput()}>Subir documento</button></li> }
									<li><button className="dropdown-item btn btn-link rounded-0" onClick={() => setEditFolderModal({})}>Crear carpeta</button></li>
									{!isFilePicker &&
										<li><button className="dropdown-item btn btn-link rounded-0" onClick={() => copyFromFolderClickHandler()}>Copiar desde carpeta</button></li>
									}
								</ul>
							</div>
						</div>
						<div className="col-3 col-md-2 d-flex">
							{!isFilePicker &&
								<div className="form-check form-switch d-flex align-items-center">
									<input className="form-check-input mb-1" type="checkbox" id="select-multiple-switch" checked={selectMultiple} onChange={(e) => setSelectMultiple(e.target.checked)} />
									<label className="form-check-label ms-2" htmlFor="select-multiple-switch">Selección múltiple</label>
								</div>
							}
							{(selectMultiple && selectedFiles.length > 0) &&
								<div className="dropdown d-inline-block ms-2" id="multiple-dropdown">
									<button className="btn btn-sm p-0 px-1 btn-primary text-white" type="button" data-bs-toggle="dropdown">
										<i className="bi bi-caret-down"></i>
									</button>
									<ul className="dropdown-menu">
										<li><button className={'dropdown-item btn btn-link rounded-0'} onClick={() => setCopyMoveFileModal(selectedFiles, 'move')}>Mover</button></li>
										<li><button className={'dropdown-item btn btn-link rounded-0'} onClick={() => setCopyMoveFileModal(selectedFiles, 'copy')}>Copiar</button></li>
									</ul>
								</div>
							}
						</div>
						<div className="col-3 col-md-1 text-end">
							{!isFilePicker &&
								<>
									{(folder?.subfolders?.length > 0 || folder?.files?.length) > 0 &&
										<button className="btn btn-sm btn-light" onClick={() => downloadFolderFix(folder)}>
											<i className="bi bi-download"></i>
										</button>
									}
									{folder?.deletable === 1 && (!folder?.business || authUserPermission('delete', folder?.business)) &&
										<button className="btn btn-sm btn-danger text-white ms-2" onClick={() => deleteFolder(folder)}>
											<i className="bi bi-trash"></i>
										</button>
									}		
								</>
							}			
						</div>
						<div className={'col-md-' + (isPanel ? '3' : '3')}>
							<input type="search" className="form-control form-control-sm mt-2 mt-md-0" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} value={search} />
						</div>
					</div>
				</ActionsBar>

				{ !isPanel && 
					<BreadCrumbs>
						{ !business?.id &&
							<span className={folder.id !== -1 ? 'clickable' : ''} onClick={() => openFolder(null)}>
								<span className="name">Documentos</span> 
								{ folder.breadcrumbs?.length > 0 && 
									<span className="caret">&nbsp;{'>'}&nbsp;</span> 
								}
							</span>
						}

						{ (folder.id && folder.breadcrumbs) &&
							folder.breadcrumbs.map((el, idx) => {
								let clickable = idx < folder.breadcrumbs.length-1;
								return (
									<span key={idx} className={clickable ? 'clickable' : ''} onClick={() => clickable ? openFolder(el) : null}>
										<span className="name">{el.name}</span> {clickable ? <span className="caret">{'>'}&nbsp;</span> : ''} 
									</span>
								)
							})
						}
					</BreadCrumbs>
				}

				{ folder.id && 
					<TableScrollable isPanel={isPanel}>
						<Table className={isPanel ? 'isPanel' : ''}>
							<thead>
								<tr>
									<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
									<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Fecha</ThSortable>
									<ThSortable direction={sortDirection} active={sortField === 'size'} onClick={() => sortTableClick('size')}>Tamaño</ThSortable>
									<ThSortable direction={sortDirection} active={sortField === 'userName'} onClick={() => sortTableClick('userName')}>Usuario</ThSortable>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{ (folder.id !== -1 && folder.id !== rootFolderId) &&
									<tr 
										key="parent"
										onDrop={(e) => dropFolderHandler(e)}
										onDragOver={(e) => dragOverFolderHandler(e)} 
										onDragLeave={(e) => dragLeaveFolderHandler(e)}
										data-id={folder.parent?.id ?? -1}
									>
										<td colSpan="100%" className="folder_name" onClick={() => openFolder(folder.parent ?? null)}>
											<i className="bi bi-arrow-90deg-up"></i> 
											<span>..</span>
										</td>
									</tr>
								}
								{ folder.subfolders.length > 0 &&
									folder.subfolders.map((el, idx) => {
										// Search
										if ( search.length ) {
											if ( el.name.toLowerCase().trim().indexOf(search.toLowerCase().trim()) === -1 ) {
												return null;
											} 
										}

										resultsCount++;

										let canEdit = !el.business || authUserPermission('edit', el.business);
										let canDelete = el.deletable === 1 && (!el.business || authUserPermission('delete', el.business));

										return (
											<tr 
												key={idx} 
												onDrop={(e) => dropFolderHandler(e)}
												onDragOver={(e) => dragOverFolderHandler(e)} 
												onDragLeave={(e) => dragLeaveFolderHandler(e)}
												data-id={el.id}
												data-code={el.code}
											>
												<td className="folder_name" onClick={() => openFolder(el)}>
													<div className="d-flex align-items-center">
														<i className="bi bi-folder-fill"></i> 
														<span>{el.name}</span>
													</div>

													<div className="d-block responsive_details">
														{ (el.parent_id || el.deletable === 1) && // Show business when is normal deletable folder or not deletable but is not in root folder
															<div>
																{/* { el.business && !isPanel ? el.business.name : '' } */}
																{ !el.business && 'Disponible en todas las empresas'}
															</div>
														}
													</div>
												</td>
												<td>
													<div>{el.created_at ? moment(el.created_at).format('DD-MM-YYYY') : ''}</div>
													<div>{el.created_at ? moment(el.created_at).format('HH:mm') : ''}</div>
												</td>
												<td>{readableSize(el.size)}</td>
												<td>{el.user?.name ?? 'Sistema'}</td>
												<td>
													<div className="dropdown" id="edit-dropdown">
														<button className="btn btn-sm btn-light text-white dropdown-toggle" type="button" data-bs-toggle="dropdown">
															<i className="bi bi-justify"></i>
														</button>
														<ul className="dropdown-menu">
															<li><button className={'dropdown-item btn btn-link rounded-0 ' + (!canEdit ? 'disabled' : '')} onClick={() => setEditFolderModal(el)}>Editar</button></li>
															<li><button className={'dropdown-item btn btn-link rounded-0 ' + (!canDelete ? 'disabled' : '')} onClick={() => deleteFolder(el)}>Eliminar</button></li>
															<li><button className="dropdown-item btn btn-link rounded-0" onClick={() => downloadFolderFix(el)}>Descargar</button></li>
														</ul>
													</div>
												</td>
											</tr>
										);
									})
								}
								{ folder.files.length > 0 &&
									folder.files.map((el, idx) => {
										// Search
										if ( search.length ) {
											if ( el.name.toLowerCase().trim().indexOf(search.toLowerCase().trim()) === -1 ) {
												return null;
											} 
										}

										resultsCount++;

										// Get icon 
										let icon_class = 'bi ';
										if ( el.extension === 'pdf' ) icon_class += 'bi-file-pdf';
										if ( el.mimetype && el.mimetype?.indexOf('video/') !== -1 ) icon_class += 'bi-file-earmark-play';
										if ( el.mimetype && el.mimetype?.indexOf('image/') !== -1 ) icon_class += 'bi-file-earmark-image';
										if ( el.extension.indexOf('xls') !== -1 || el.extension === 'ods' ) icon_class += 'bi-file-earmark-excel';
										if ( el.extension.indexOf('doc') !== -1 || el.extension === 'odt' ) icon_class += 'bi-file-earmark-word';
										if ( icon_class === 'bi ') icon_class += 'bi-file-fill';

										let canEdit = !el.business || authUserPermission('edit', el.business);
										let canDelete = !el.business || authUserPermission('delete', el.business);

										return (
											<tr key={idx}>
												<td 
													className="file_name" 
													draggable="true" 
													onDragStart={(e) => dragStartHandler(e, el)}
												>
													<div className="d-flex align-items-center">
														{ (selectMultiple && canEdit) &&
															<input type="checkbox" className="me-1" checked={selectedFiles.findIndex(fEl => fEl.id === el.id) !== -1} onChange={() => toggleSelectedFile(el)} />
														}
														<i className={icon_class}></i> 
														<NavLink to={'/documents/view-file/'} onClick={(e) => clickFileHandler(e, el)}>{el.name}</NavLink>
													</div>

													<div className="responsive_details">
														<div>
															{ el.business && !isPanel ? el.business.name : '' }
															{ !el.business && 'Disponible en todas las empresas'}
														</div>
													</div>

													<div className="d-block d-md-none responsive_details">
														<div>{el.created_at ? moment(el.created_at).format('DD-MM-YYYY HH:mm') : ''}</div>
														<div>{readableSize(el.size)}</div>
														<div>{el.user?.name}</div>
													</div>
												</td>
												<td>
													<div>{el.created_at ? moment(el.created_at).format('DD-MM-YYYY') : ''}</div>
													<div>{el.created_at ? moment(el.created_at).format('HH:mm') : ''}</div>
												</td>
												<td>{readableSize(el.size)}</td>
												<td>{el.user?.name}</td>
												<td>
													<div className="dropdown" id="edit-dropdown">
														<button className="btn btn-sm btn-light text-white dropdown-toggle" type="button" data-bs-toggle="dropdown">
															<i className="bi bi-justify"></i>
														</button>
														<ul className="dropdown-menu">
															<li><button className="dropdown-item btn btn-link rounded-0" onClick={() => downloadFileFix(el)}>Descargar</button></li>
															{/* <li>
																<NavLink 
																	to={
																		'/emails/add?attach_file_id=' + el.id + 
																		(props.recipientsType ? '&recipients_type=' + props.recipientsType : '') + 
																		(props.recipientsIds ? '&recipients_ids=' + props.recipientsIds : '')
																	} 
																	className="dropdown-item btn btn-link rounded-0" 
																	onClick={(e) => openSendEmailPopupWindow(e)}
																>
																	Enviar
																</NavLink>
															</li> */}
															<li><button className={'dropdown-item btn btn-link rounded-0 ' + (!canEdit ? 'disabled' : '')} onClick={() => setEditFileModal(el)}>Editar</button></li>
															<li><button className={'dropdown-item btn btn-link rounded-0 ' + (!canEdit ? 'disabled' : '')} onClick={() => setCopyMoveFileModal([el], 'move')}>Mover</button></li>
															<li><button className={'dropdown-item btn btn-link rounded-0 ' + (!canEdit ? 'disabled' : '')} onClick={() => setCopyMoveFileModal([el], 'copy')}>Copiar</button></li>
															<li><button className={'dropdown-item btn btn-link rounded-0 ' + (!canDelete ? 'disabled' : '')} onClick={() => deleteFile(el)}>Eliminar</button></li>
														</ul>
													</div>
												</td>
											</tr>
										);
									})
								}

								{ (resultsCount === 0) &&
									<tr key='empty'>
										<td colSpan="100%">No hay documentos</td>
									</tr>
								}
							</tbody>
						</Table>
					</TableScrollable>
				}
			</ExplorerStyled>

			{ editFolderModal &&
				<FolderModal
					parentFolder={folder}
					folder={editFolderModal}
					closeCallback={() => {
						openFolder(folder);
						setEditFolderModal(null);
						props.updateTree();
						document.body.style.overflow = 'auto'; // Fix, because bootstrap removes scroll on modal opened
						document.body.style.paddingRight = '0'; // Fix, because bootstrap add padding when modal is closed
					}}
					business={business}
				/>
			}

			{ editFileModal &&
				<FileModal
					parentFolder={folder}
					file={editFileModal}
					closeCallback={() => {
						openFolder(folder);
						setEditFileModal(null);
						document.body.style.overflow = 'auto'; // Fix, because bootstrap removes scroll on modal opened
						document.body.style.paddingRight = '0'; // Fix, because bootstrap add padding when modal is closed
					}}
					business={business}
				/>
			}

			{ copyMoveFileModal &&
				<CopyMoveModal
					files={copyMoveFileModal}
					action={copyMoveFileModalAction}
					closeCallback={(reset) => {
						openFolder(folder);
						setCopyMoveFileModal(null);
						if ( reset ) setSelectedFiles([]);
						
						document.body.style.overflow = 'auto'; // Fix, because bootstrap removes scroll on modal opened
						document.body.style.paddingRight = '0'; // Fix, because bootstrap add padding when modal is closed
					}}
					business={business}
				/>
			}

			<FileHidden type="file" ref={fileRef} multiple="multiple" onChange={(e) => handleFiles(e.target.files)} />
		</React.Fragment>
	);
}